import React, {useState} from "react";

import "./AdministrationPage.css";

import {Col, Tab, Tabs} from "react-bootstrap";
import CustomContainer from "../../components/CustomContainer";
import Loader from "../../components/Loader";
import GroupPermissionsComponent from "../../components/permissions/GroupPermisionsComponent";
import AuditComponent from "../../components/audit/AuditComponent";

const AdministrationPage = (props) => {

    const [loader, setLoader] = useState(false);

    return (
        <>

            <Loader loader={loader}/>
            <CustomContainer title={"Panel administracyjny"}>
                <Col>
                    <div>
                        <Tabs id="adminActions">
                            {/*<Tab eventKey="permissions" title="Uprawnienia">*/}
                            {/*    <GroupPermissionsComponent/>*/}
                            {/*</Tab>*/}
                            <Tab eventKey="audit" title="Śledzenie">
                                <AuditComponent setLoader={setLoader}/>
                            </Tab>

                        </Tabs>
                    </div>
                </Col>


            </CustomContainer>


        </>
    );
};


export default AdministrationPage;
