import React, {useEffect, useState} from "react";
import CardForm from "../../components/form/CardForm";
import {Actions} from "../../../client/Actions";
import fields from "./config/ToolSelectionFormConfig";
import {Alert, Button, Col, Row} from "react-bootstrap";
import MeasurementConfirmationFormConfig from "./config/MeasurementConfirmationFormConfig";

const uniqueArray = (arr) => [...new Set(arr)];

const ToolSelectionSection = ({
                                  setToolInVerification,
                                  verificationId,
                                  verification,
                                  confirmed,
                                  setConfirmed,
                                  setLoader,
                                  onSaved
                              }) => {


    const customSave = (form, onSaved) => {
        setToolInVerification(form.tool);
        setLoader(false);
    };

    const customSaveConfirmed = (form, onSaved) => {
        setConfirmed(form.confirmed)
        setLoader(false)
    };

    const updateConfirmed = () => {
        if (verification && verification.configuration) {
            if (verification.configuration.measurementType === "TOTAL_LENGTH") {
                setShouldConfirm(true)
            } else {
                setShouldConfirm(false)
            }

        }
    }


    const [dictionaries, setDictionaries] = useState()
    const [shouldConfirm, setShouldConfirm] = useState(false);


    function updateDictionaries() {
        const tmp = {}
        tmp["enums/toolType"] = uniqueArray(verification.tools.filter(t => !t.verified).map(t => t.toolType));
        tmp["tools"] = verification.tools.filter(t => !t.verified);
        setDictionaries(tmp)
    }

    useEffect(updateDictionaries, [verification])
    useEffect(updateConfirmed, [verification])

    return (
        <>
            {verification && !verification.completed && dictionaries &&

                <>
                    {shouldConfirm && !confirmed && <Row>
                        <Col>
                            <CardForm
                                title={"Zerowanie czujnika"}
                                setLoader={setLoader}
                                fields={MeasurementConfirmationFormConfig}
                                api={"verification"}
                                width={12}
                                saveOnValid={true}
                                className={"mx-0 px-0"}
                                customSave={customSaveConfirmed}
                                saveButton={"Potwierdż"}
                                reverseButtons={true}
                                readOnly={confirmed}
                                withoutCancel={true}
                                postSaved={onSaved}
                            />
                        </Col>
                    </Row>}
                    {shouldConfirm && confirmed && <Row>
                        <Col>
                                <Alert className={"pb-4"} variant="primary"><i
                                    className="fas fa-info-circle mr-2"></i><strong>
                                    Potwierdzono, że czujnik został wyzerowany </strong>


                                </Alert>
                        </Col>
                    </Row>}
                    {(shouldConfirm ? confirmed : true) &&
                        <CardForm
                            title={"Wybierz narzędzie"}
                            setLoader={setLoader}
                            fields={fields}
                            api={"verification"}
                            width={12}
                            dictionaries={dictionaries}
                            className={"mx-0 px-0"}
                            customSave={customSave}
                            saveButton={"Zacznij weryfikację"}
                            reverseButtons={true}
                            postSaved={onSaved}
                        />
                    }
                </>
            }
        </>
    );
};


export default ToolSelectionSection;