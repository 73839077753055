import React, { useRef } from "react";
import html2canvas from "html2canvas";
import { Badge, Button } from "react-bootstrap";
import { QRCode } from "react-qrcode-logo";

const QrCode = ({ value, filePrefix }) => {
  const qrCodeWrapperRef = useRef(null);


  const downloadQRCode = () => {
    const qrCodeWrapper = qrCodeWrapperRef.current;

    if (!qrCodeWrapper) {
      console.error("Error: QRCode wrapper element not found.");
      return;
    }

    // Use html2canvas to capture the QR code as a canvas
    html2canvas(qrCodeWrapper)
      .then((canvas) => {
        // Convert the canvas to a data URL (PNG format)
        const dataUrl = canvas.toDataURL("image/png");

        // Create a link element
        const a = document.createElement("a");
        a.href = dataUrl;
        a.download = (filePrefix ? (filePrefix + "_") : "") + value + "_qrcode.png";

        // Trigger a click event on the link to initiate the download
        a.click();
      })
      .catch((error) => {
        console.error("Error capturing QR code:", error);
      });
  };

  return (
    <>
      <div>
        <div ref={qrCodeWrapperRef} >
          <div className="justify-content-center d-flex mt-3">
            <QRCode value={`${value}`} size={250} />
          </div>
          <div className="justify-content-center d-flex mt-1">
            <Badge className={"mb-2"} variant={"primary"}>{value}</Badge>
          </div>
        </div>
        <div className="justify-content-center d-flex mt-3">
          <Button variant="outline-primary" onClick={downloadQRCode}>Pobierz kod QR</Button>
        </div>
      </div>
    </>
  );
};

export default QrCode;