import * as React from "react";
import SimpleDictionaryFormConfig from "../../../components/common/SimpleDictionaryFormConfig";

const fields = {
    config: {columns: 2},
    fields: [
        {
            name: "withCamera",
            label: "Weryfikacja wizualna",
            column: 1,
            type: "boolean",
            mandatory: true,
            compareWith: "withCamera",
            compare: function (withCamera, withMeasurements) {
                return withMeasurements === "true" || withCamera === "true";
            }
        },
        {
            name: "withMeasurements",
            label: "Pomiary",
            column: 1,
            type: "boolean",
            mandatory: true,
            validate: {
                compareWith: "withCamera",
                compare: function (withMeasurements, withCamera) {
                    return withMeasurements === "true" || withCamera === "true";
                }
            },
        },
        {
            name: "measurementType",
            label: "Typ pomiaru",
            column: 1,
            type: "enum",
            enumType: "enums/measurementType",
            mandatory: true,
            conditionalOn: "withMeasurements"
        }

    ]


};

export default fields;