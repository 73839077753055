import React, { useEffect, useState } from "react";
import CustomCard from "../CustomCard";
import FormGenerator from "./FormGenerator";
import { Actions } from "../../../client/Actions";
import ConfirmModal from "../modal/ConfirmModal";
import { Row } from "react-bootstrap";
import QrCode from "../qrCode/QrCode";
import IconButton from "../button/IconButton";

const CardFormDetails = ({
                           setLoader,
                           initialData,
                           shadow,
                           fields,
                           reload,
                           underCard,
                           qrCode,
                           api,
                           className,
                           readonly,
                             withoutHeader,
                           title,
                           width = 6
                         }) => {

  const [formData, setFormData] = useState();
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);


  const loadData = () => {
    if (initialData) {
      onDataLoaded(initialData);
    } else {
      setLoader(true);
      Actions.get(api, onDataLoaded);
    }


  };

  const onDataLoaded = (response) => {
    setFormData(response);
    setLoader(false);
  };

  function prepareTittle() {
    return <>
      <div className="float-left pt-2">
        {title}
      </div>

      {qrCode ?
        <div className="float-right">

          <IconButton
            variant="outline-primary"
            onClick={() => setShowQrCodeModal(true)}
            icon="fa-qrcode"
            tooltip="Kod QR"
          />

        </div>
        : ""
      }
    </>;
  }


  useEffect(loadData, [reload]);


  return (
    <>

      <CustomCard title={prepareTittle()} shadow={shadow}
                  underCard={underCard}
                  className={className}
                  withoutHeader={withoutHeader}
                  width={width}>
        <FormGenerator fields={fields}
                       formData={formData}
                       initData={true}
                       setFormData={setFormData}
                       readOnly={readonly} />

      </CustomCard>

      {qrCode && formData && formData[qrCode] &&
        <ConfirmModal
          title={"Kod QR"}
          size={"md"}
          show={showQrCodeModal}
          setShow={setShowQrCodeModal}
          alert
          children={

            <Row className="justify-content-center">
              <QrCode value={formData[qrCode]} filePrefix={"filter"} />
            </Row>

          }
        />
      }

    </>
  );
};


export default CardFormDetails;