import axios from "axios";
import { getJwt } from "../helpers/jwt";
import utils from "../helpers/utils";

function isTokenInvalid(r) {
  console.log(r);
  if (r.response && r.response.status === 401) {
    localStorage.removeItem("user_jwt");
  } else if (r.response && r.response.status === 403) {
    alert("Dostęp zabroniony!");
  }
}

const baseUrl = utils.defaultUrl();

const pagesActions = {
  validateUser: (props) => {
    const token = getJwt();
    if (!token) {
      props.history.push("/login");
    }
  },
  login: (email, password, callback) => {
    axios.post(baseUrl + "/api/authenticate", {
      username: email,
      password: password
    })
      .then(function(response) {
        callback({
          status: "OK",
          msg: {
            message: "User logged succesfully.",
            type: "success",
            jwtToken: response.data.id_token,
            user: response.data.userData.username,
            perms: response.data.userData.perms
          }
        });
      })
      .catch(function(error) {
        console.log(error);
        isTokenInvalid(error);
        callback({
          status: "ERROR",
          msg: {
            message: "Dane do logowania nie są poprawne",
            type: "error"
          }
        });
      });
  },

};


export default pagesActions;
