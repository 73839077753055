import React, {useState} from "react";
import CustomContainer from "../../components/CustomContainer";
import Loader from "../../components/Loader";
import CardTable from "../../components/table/CardTable";
import columns from "./config/VerificationTableConfig";

const MainPage = (props) => {

    const [loader, setLoader] = useState(false);



    return (
        <>
            <Loader loader={loader}/>
                <CustomContainer title={"Weryfikację niezakończone"}
                                 className={"px-4"}>


                    <CardTable
                        tittle={"Lista weryfikacji"}
                        source={"verification"}
                        columns={columns}
                        width={12}
                        setLoader={setLoader}
                        striped={false}
                        shadow={true}
                        additionalFilter={(row) => !row.completed}
                    />
                </CustomContainer>
        </>
    );
};

export default MainPage;
