import React from "react";
import CardTable from "../../components/table/CardTable";
import {Card} from "react-bootstrap";
import columns from "./config/ToolTableConfig";
import {hasFiltersCreateGroup} from "../../helpers/jwt";

const ToolsSection = ({verificationId, verification, setLoader, reload}) => {


    function getTitle() {
        const verified = verification.tools.filter(t=>t.verified).length
        const all = verification.tools.length


        return <>
            Narzędzia

            <div className={"float-right"}>
                <span className={"text-secondary font-weight-normal"}>Zweryfikowano</span>
                <span className={`badge badge-primary-outline mr-1 ml-1`}>
                     <span>{verified + "/" + all}</span>
                </span>
            </div>


        </>;
    }

    return (
        <>
            {verification &&


                <CardTable
                    tittle={getTitle()}
                    initData={verification.tools}
                    columns={columns}
                    width={12}
                    setLoader={setLoader}
                    reload={reload}
                    striped={false}
                    className={"mx-0 px-0"}
                    shadow={true}
                />
            }

        </>
    );
};


export default ToolsSection;