import * as React from "react";

const fields = {
  config: { columns: 1 },
  fields: [
    {
      name: "name",
      label: "Nazwa",
      column: 1,
      mandatory: true

    },
  ]


};

export default fields;