import React, {useEffect, useState} from "react";
import {MDBDataTable} from "mdbreact";
import {Actions} from "../../../client/Actions";
import {useHistory} from "react-router-dom";
import {Col, Form, Row} from "react-bootstrap";
import FileModal from "../FileModal";
import ConfirmModal from "../modal/ConfirmModal";
import IconButton from "../button/IconButton";
import QrCode from "../qrCode/QrCode";
import renderUtils from "../../helpers/renderUtils";
import BtnSliderRoundedComponent from "../switch/BtnSliderRoundedComponent";
import Tippy from "@tippyjs/react";

const Table = ({
                   source,
                   columns,
                   setLoader,
                   pagination,
                   initData,
                   checked,
                   setChecked,
                   providedData,
                   disableRule,
                   reload,
                   striped,
                   conditionalRowStyles,
                   additionalFilter,
                   readOnly,
                   actions
               }) => {


    const history = useHistory();

    const [filePresentation, setFilePresentation] = useState({});
    const [textareaPresentation, setTextareaPresentation] = useState({});
    const [qrCodePresentation, setQrCodePresentation] = useState({});
    const [internalReload, setInternalReload] = useState(false);
    const [locked, setLocked] = useState([]);


    const filterSlider = (filter, check) => {
        setChecked((prevChecked) => {
            if (!check || prevChecked.find(f => f.id === filter.id)) {
                return prevChecked.filter(c => c.id !== filter.id);
            } else {
                return [...prevChecked, filter];
            }
        });
    };

    const filterChecked = (filter) => {
        setChecked((prevChecked) => {
            if (prevChecked.find(f => f.id === filter.id)) {
                return prevChecked.filter(c => c.id !== filter.id);
            } else {
                return [...prevChecked, filter];
            }
        });
    };


    function handleShowFileModal(title, files) {

        setFilePresentation({
            title: title,
            files: files,
            show: true
        });
    }

    function handleShowQrCodeModal(qrCode) {

        setQrCodePresentation({
            value: qrCode,
            show: true
        });
    }

    function handleShowTextAreaModal(title, files) {

        setTextareaPresentation({
            title: title,
            value: files,
            show: true
        });
    }

    const [data, setData] = useState({
        rows: [],
        columns: columns.filter(c => !readOnly ? true : !c.hideForReadonly)
    });

    function reloadChecked() {
        if (initData) {
            reloadData();
        }
    };

    function reloadData() {
        if (initData) {
            onDataLoaded(initData);
        } else if (source) {
            setLoader(true);
            Actions.get(source, onDataLoaded);
        }
    };

    const onDataLoaded = (response) => {
        setData({
            ...data,
            rows: prepareRows(response)
        });

        setLoader(false);
    };

    useEffect(reloadData, [internalReload, locked, reload, initData]);
    useEffect(reloadChecked, [checked]);


    const prepareRows = (response) => {
        return response
            .filter(r => additionalFilter ? additionalFilter(r) : true)
            .map((r, i) => prepareRow(i, r));
    };

    const prepareRow = (i, row) => {
        let result = {};


        columns.forEach(column => {
            let preparedValue = column.render ? column.render(row) : prepareValue(
                column,
                i,
                column.parent ? row[column.parent] : row
            );

            result[column.field] = wrapValue(preparedValue, column, row);
        });

        return result;

    };

    function buildQrCodeModalComponent(column, row) {

        return (<IconButton
            variant="outline-primary"
            onClick={() => handleShowQrCodeModal(row[column.qrCode])}
            icon="fa-qrcode"
            tooltip="Kod QR"
        />);
    }

    const prepareValue = (column, i, row) => {


        const value = row[column.field];
        let {field, type, label, enumType, wrap} = column;


        if (type === "dictionary") {
            if (value) {
                return value.name;
            }
            return undefined;

        }

        if (type === "index") {
            return i + 1;
        }

        if (type === "length") {
            return value ? value.length : 0;
        }


        if (type === "boolean") {
            if (value === true) {
                return "Tak";
            }

            if (value === false) {
                return "Nie";
            }

            return undefined;
        }

        if (type === "enum") {
            return renderUtils.renderEnumName(value, enumType);
        }

        if (type === "custom") {
            return column.custom(column, row, history, internalReload, setInternalReload, setLoader, disableRule, readOnly, actions);
        }

        if (type === "file") {
            return buildModalComponent(
                row,
                (row) => row[field] && row[field].length > 0,
                column,
                () => handleShowFileModal(label, row[field])
            );
        }

        if (type === "textarea") {
            return buildModalComponent(
                row,
                (row) => row[field] && row[field] !== "",
                column,
                () => handleShowTextAreaModal(label, row[field])
            );
        }

        if (type === "qrCode") {
            return buildQrCodeModalComponent(column, row);
        }

        if (type === "checkbox") {
            return buildTableCheckbox(row);
        }

        if (type === "slider") {
            return buildTableSlider(row);
        }


        if (type === "lock") {
            return buildTableLock(row);
        }

        return value;

    };

    function buildTableCheckbox(row) {
        return <div id={"checkbox-" + row.id} className="">
            <label className="checkbox-container">
                <input type="checkbox" onChange={() => filterChecked(row)}/>
                <span className="checkmark"></span>
            </label>
        </div>;
    }

    function buildTableLock(row) {
        if (!locked.find(l => l === row.id)) {
            return <IconButton icon="fa-lock-open"
                               className={"btn-circle"}
                               color="primary"
                               onClick={() => {
                                   setLocked(old => [...old, row.id]);
                               }
                               }
                               tooltip="Zablokuj"/>;
        } else {
            return <IconButton icon="fa-lock" color="primary"
                               className={"btn-circle"}

                               onClick={() => setLocked(locked.filter(l => l !== row.id))}
                               tooltip="Odblokuj"/>;
        }

    }

    function buildTableSlider(row) {
        const lock = locked.find(l => l === row.id);
        const isChecked = checked.find(c => c.id === row.id);

        return <div className={lock ? "opacity-30" : ""}>
            <BtnSliderRoundedComponent checked={isChecked}

                                       setState={(check) => filterSlider(row, check)}
                                       disabled={lock || readOnly} red={!isChecked}/>
        </div>;
    }

    function buildModalComponent(row, isAdded, column, onClick) {

        return (
            <>
                {/*{!isAdded(row) &&*/}

                {/*    <Form.Row>*/}
                {/*        <Col>*/}
                {/*            <Form.Label className="col-form-label text-warning">*/}
                {/*                Nie dodano*/}
                {/*            </Form.Label>*/}
                {/*        </Col>*/}
                {/*    </Form.Row>*/}
                {/*}*/}
                {isAdded(row) &&

                    <Form.Row>
                        <Col>
                            <Form.Label className="col-form-label cursor-pointer"
                                        onClick={onClick}>
                                <span className="text-success">
                                         <Tippy content={"Podgląd"} interactive={true} placement="bottom">
                                            <i className="fas fa-info-circle ml-1 h5"/>
                                         </Tippy>

                                </span>
                            </Form.Label>
                        </Col>
                    </Form.Row>
                }
            </>
        );
    }


    const wrapValue = (value, column, row) => {

        if (!value) {
            return "-";
        }


        if (column.wrap) {
            return <span className={"wrap-text"}>{value}</span>;
        }

        if (column.type !== "custom" && disableRule && disableRule(row)) {
            return <div className={"disabled"}>{value}</div>;
        } else
            return value;
    };


    return (
        <>
            <MDBDataTable
                striped={striped ? striped : false}
                searchLabel="Szukaj"
                infoLabel={["Pokazano od", "do", "z", ""]}
                entriesLabel="Pokaż"
                noRecordsFoundLabel="Brak"
                paginationLabel={["«", "»"]}
                paging={pagination !== false}
                searching={pagination !== false}
                data={data}
                conditionalRowStyles={conditionalRowStyles}
            />

            {filePresentation &&
                <FileModal
                    title={filePresentation.title}
                    show={filePresentation.show}
                    setShow={() => setFilePresentation(undefined)}
                    files={filePresentation.files}
                    setFiles={() => {
                    }}
                />
            }

            {textareaPresentation &&
                <ConfirmModal
                    title={textareaPresentation.title}
                    size={"md"}
                    show={textareaPresentation.show}
                    setShow={() => setTextareaPresentation(undefined)}
                    alert
                    children={
                        <>
                            <Form.Group>
                                <Form.Control
                                    as={"textarea"}
                                    disabled={true}
                                    value={textareaPresentation.value}
                                    type="text"
                                />
                            </Form.Group>
                        </>

                    }
                />
            }


            {qrCodePresentation &&
                <ConfirmModal
                    title={"Kod QR"}
                    size={"md"}
                    show={qrCodePresentation.show}
                    setShow={() => setQrCodePresentation(undefined)}
                    alert
                    children={

                        <Row className="justify-content-center">
                            <QrCode e value={qrCodePresentation.value} filePrefix={"filterSet"}/>
                        </Row>

                    }
                />
            }
        </>
    );
};

export default Table;