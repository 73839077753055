import * as React from "react";

const fields = {
    config: {columns: 1},
    fields: [
        {
            name: "fileName",
            label: "Nazwa pliku",
            column: 1,
            mandatory: true,
        }, {
            name: "fileDescription",
            label: "Opis",
            type: "textarea",
            column: 1,
        }

    ]


};

export default fields;