import React from "react";

import CardFormDetails from "../../components/form/CardFormDetails";
import CardForm from "../../components/form/CardForm";
import {Actions} from "../../../client/Actions";
import fields from "./config/VerificationConfigurationConfig";

const ConfigurationStep = ({verificationId, verification, setLoader, onSaved}) => {

    const customSave = (configuration, onSaved) => {
        Actions.post(`verification/${verificationId}/configuration`, configuration, onSaved);
    };

    return (
        <>
            {
                verification && verification.configuration ?
                    <CardFormDetails
                        setLoader={setLoader}
                        fields={fields}
                        width={12}
                        withoutHeader={true}
                        className={"mt-2 mx-0 px-0"}
                        initialData={verification.configuration}
                        readonly={true}
                    />
                    :
                    <CardForm
                        setLoader={setLoader}
                        fields={fields}
                        width={12}
                        className={"mt-2 mx-0 px-0"}
                        customSave={customSave}
                        withoutHeader={true}
                        postSaved={onSaved}
                    />
            }

        </>
    );
};


export default ConfigurationStep;