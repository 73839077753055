import React, {useState} from "react";
import {Form, Col, Button, ListGroup, Row} from "react-bootstrap";
import pagesActions from "../../pages/pagesActions";
import Loader from "../Loader";
import utils from "../../helpers/utils";
import {Actions} from "../../../client/Actions";


const FormInputFile = ({
                           titleText,
                           labelText,
                           idName,
                           path,
                           requestParameterName,
                           setImagesParentStore,
                           imagesParentStore,
                           inputSize,
                           btnSize,
                           withoutList,
                           disabled
                       }) => {

    const default_label_txt = "Wybierz plik";

    const [store, setStore] = useState(null);
    const [labelTxt, setLabelTxt] = useState(labelText ? labelText : default_label_txt);
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    // loader
    const [loader, setLoader] = useState(false);

    const validateAndUpload = event => {
        setLoader(true);
        const newformData = new FormData();
        newformData.append(requestParameterName, store);
        newformData.append("description", null);
        Actions.post("file", newformData, callback);
    };


    const onImageChange = event => {
        if (event.target.files.length > 0) {
            setStore(event.target.files[0]);
            setLabelTxt(event.target.files[0].name);
            // activate btn
            setIsBtnDisabled(false);
        } else {
            setLabelTxt(default_label_txt);
            setStore(null);
            setIsBtnDisabled(true);
        }
    };

    const onImageInputClick = event => {
        event.target.value = null;
        setStore(null);
        setIsBtnDisabled(true);
    };

    const callback = (data) => {

        if (withoutList) {
            setImagesParentStore(old => [...old, {
                id: data.id,
                fileName: data.fileName,
                filePath: data.filePath,
                description: data.description,
            }])
        } else {
            imagesParentStore.push({
                id: data.id,
                fileName: data.fileName,
                filePath: data.filePath,
                description: data.description,

            });
            setImagesParentStore(imagesParentStore);
        }


        // reset
        setStore(null);
        setLabelTxt(default_label_txt);
        setIsBtnDisabled(true);


        setLoader(false);
    };

    const handleDeleteImage = (id) => {
        callbackDeleteToolSetImage(id)
        // Actions.delete("file/" + id, {}, () => callbackDeleteToolSetImage(id));
    };

    const callbackDeleteToolSetImage = (id) => {
        const items = imagesParentStore.filter(item_image => item_image.id !== id);
        setImagesParentStore(items);
    };

    const handleClickImg = (filePath) => {
        window.open(utils.defaultUrl() + filePath, "_blank", "width=1100,height=400px,resizable=1");
    };

    return (
        <>
            <Loader loader={loader} setLoader={setLoader}/>
            <Form.Label><strong>{titleText}</strong></Form.Label>
            {!withoutList &&
                <ListGroup variant="flush">
                    {imagesParentStore.map((el) =>
                        <ListGroup.Item key={el.id}>
                            <Row>
                                <Col md={disabled ? 12 : 7}>
                                    <div className="file_name_text_input_form"
                                         onClick={e => handleClickImg(el.filePath)}>
                                        {el.fileName}
                                    </div>
                                </Col>
                                <Col md={disabled ? 0 : 5}>
                                    {!disabled &&
                                        <i className="fas fa-trash-alt delete_btn_image cursor-pointer"
                                           onClick={e => handleDeleteImage(el.id)}/>}
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    )}
                    {imagesParentStore.length == 0 && disabled ? "Brak załączników" : ""}
                </ListGroup>
            }
            {!disabled ?
                <Form.Row className="mt-2">
                    <Col md={inputSize ? inputSize : 9}>
                        <Form.Group>
                            <Form.File id={idName} label={labelTxt} land="pl" custom onChange={onImageChange}
                                       disabled={disabled}
                                       onClick={onImageInputClick}/>
                        </Form.Group>
                    </Col>
                    <Col md={btnSize ? btnSize : 3}>
                        <Form.Group>

                            <Button variant="primary" className={"full_content_btn upload_input_" + idName}
                                    disabled={isBtnDisabled}
                                    onClick={validateAndUpload}> Wgraj plik </Button>
                        </Form.Group>
                    </Col>
                </Form.Row> : ""}
        </>
    );
};

export default FormInputFile;