import * as React from "react";

const fields = {
    config: {columns: 1},
    fields: [
        {
            name: "toolType",
            label: "Typ narzędzia",
            column: 1,
            type: "enum",
            enumType: "enums/toolType",
        },
        {
            name: "toolNumber",
            label: "Numer narzędzia",
            column: 1,
        },
        {
            name: "labelling",
            label: "Znakowanie",
            column: 1,
        }


    ]


};

export default fields;