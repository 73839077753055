import React from "react";

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import LoginPage from "../web/pages/login/LoginPage";
import AuthComponent from "../web/components/AuthComponent";


import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {HashRouter, Route, Switch} from "react-router-dom";
import AdministrationPage from "./pages/administration/AdministrationPage";
import VerificationPage from "./pages/verification/VerificationPage";
import VerificationTablePage from "./pages/verification/VerificationTablePage";
import MainPage from "./pages/verification/MainPage";
import VerificationTemplatesPage from "./pages/verification/template/VerificationTemplatesPage";


class App extends React.Component {
  render() {
    return (
      <>
        <HashRouter>
          <Switch>
            <Route path="/login" component={LoginPage} />
            {/*<Route path="/reset-password" component={ResetPassword} />*/}
            <AuthComponent>
              <Route exact path="/" component={MainPage} />
              <Route exact path="/verifications" component={VerificationTablePage} />
              <Route exact path="/verificationTemplates" component={VerificationTemplatesPage} />
              <Route exact path="/verification" component={VerificationPage} />
              <Route exact path="/verification/:id" component={VerificationPage} />
              {/*<Route exact path="/dryers" component={DryersPage} />*/}
              {/*<Route exact path="/dryers/:id" component={DryerDetailsPage} />*/}
              {/*<Route exact path="/filter" component={FilterPage} />*/}
              {/*<Route exact path="/filter/storage" component={FiltersStoragePage} />*/}
              {/*<Route exact path="/filter/storage/:id" component={FiltersStorageDetailsPage} />*/}
              {/*<Route exact path="/filterSet" component={FilterSetPage} />*/}
              {/*<Route exact path="/filterSet/production" component={FilterSetProductionPage} />*/}
              {/*<Route exact path="/production" component={ProductionPage} />*/}
              {/*<Route exact path="/production/:id" component={ProductionDetailsPage} />*/}
              {/*<Route exact path="/management/:id/verification" component={VerificationPage} />*/}
              {/*<Route exact path="/withdrawn" component={WithdrawalPage} />*/}
              {/*<Route exact path="/washingMachine" component={WashingMachinePage} />*/}
              {/*<Route exact path="/reports" component={FilterSetReportPage} />*/}
              {/*<Route exact path="/reports/:id" component={FilterSetReportOptionsPage} />*/}
              <Route exact path="/admin" component={AdministrationPage} />

            </AuthComponent>
            {/*<Route component={NotFoundPage} />*/}
          </Switch>
        </HashRouter>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </>
    );
  }
}

export default App;