import React from "react";

import {Card, Col} from "react-bootstrap";

const CustomCard = (props) => {
    return (
        <>

            <Col md={props.width ? props.width : 12} className={props.className}>
                {props.underCard}
                <Card className={`mb-3 ${props.shadow === false ? "" : "shadow"} ${props.className}`}>
                    {!props.withoutHeader &&
                        <Card.Header className="py-3">
                            <p className="m-0 font-weight-bold">{props.title}</p>
                        </Card.Header>
                    }
                    {props.children &&
                        <Card.Body>
                            {props.children}
                        </Card.Body>
                    }
                </Card>
            </Col>

        </>
    );
};


export default CustomCard;