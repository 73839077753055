import * as React from "react";

const fields = {
    config: {columns: 1},
    fields: [
        {
            name: "selectedTemplate",
            label: "Szablon",
            mandatory: true,
            column: 1,
            type: "dictionary",
            dictionary: "toolSetTemplate",
            selectObject: true,
            render: function (d) {
                return `${d.templateName} ${d.internalNumber ? d.internalNumber : ""}`
            }
        }

    ]


};

export default fields;