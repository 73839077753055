import axios from "axios";
import { authHeader, getJwt } from "../web/helpers/jwt";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { MockActions } from "./MockActions";
import moment from "moment";

const enviroments = {
  local: "http://localhost:8080",
  senacode: "https://mpoint.senacode.pl/backend",
  sanofi: "http://rzesopt01-filters.pharma.aventis.com/api",
  tsenacode: "http://test.filters.senacode.pl/backend"
};

const client = axios.create({
  baseURL: enviroments[process.env.REACT_APP_ENVIRONMENT]
});

export const fetchData = async (path) => {
  try {
    const response = await client.get(path, authHeader());
    return response.data;
  } catch (error) {
    Actions.handleError(error, path);
  }
};


export const Actions = {


  dataPost: (path, payload, callback) => {

    client
      .post(path, payload, authHeader())
      .then((resp) => {
        callback(resp.data);
      })
      .catch((error) => {
        Actions.handleError(error, path);
      });


  },


  post: (path, payload, callback, success) => {


    client
      .post(path, payload, authHeader())
      .then((resp) => {
        if (callback) {
          callback(resp.data);
        }
        toast.success(success || "Zapisano!");
      })
      .catch((error) => {
        Actions.handleError(error, path);
      });


  },
  put: (path, payload, callback, success) => {


    client
      .put(path, payload, authHeader())
      .then((resp) => {
        if (callback) {
          callback(resp.data);
        }
        toast.success(success || "Zapisano!");
      })
      .catch((error) => {
        Actions.handleError(error, path);
      });


  },
  delete: (path, payload, callback, success) => {

    client
      .delete(path, authHeader())
      .then((resp) => {
        if (callback) {
          callback(resp.data);
        }
        toast.success(success || "Success!");
      })
      .catch((error) => {
        Actions.handleError(error, path);

      });

  },

  get: (path, callback) => {

    let action = MockActions.get(path, callback);
    if (action) {
      return;
    }


    client
      .get(path, authHeader())
      .then((resp) => {
        callback(resp.data);
      })
      .catch((error) => {
        Actions.handleError(error, path);
      });
  },


  download: (path, fileName) => {
    client
      .get(path,
        {
          responseType: "arraybuffer",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${getJwt()}`
          }
        }
      )
      .then(function(response) {
        const blob = new Blob([response.data], { type: "application/pdf" });

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName.toLowerCase()}-${moment(new Date()).format("YYYY-MM-DD-HH-mm-ss")}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(function(error) {
        Actions.handleError(error, path);
      });
  },

  isTokenInvalid: (r) => {
    console.log(r);
    if (r.response && r.response.status === 401) {
      localStorage.removeItem("user_jwt");
      window.location.reload();


      return true;
    } else if (r.response && r.response.status === 403) {
      toast.error("Dostęp zabroniony!");
      return true;
    }
  },


  handleError: (error, path) => {
    console.log(error);
    const tokenInvalid = Actions.isTokenInvalid(error);
    if (!tokenInvalid) {
      toast.error(`Wystąpił nieoczekiwany błąd! Przekaż te informację do administratora: [${path}];[${error}]`, {
        autoClose: false
      });
    }


  }
};

