import * as React from "react";

const fields = {
    config: {columns: 1},
    fields: [
        {
            name: "toolType",
            label: "Typ narzędzia",
            column: 1,
            type: "enum",
            enumType: "enums/toolType",
            mandatory: true
        },
        {
            name: "tool",
            label: "Narzędzie",
            column: 1,
            type: "dictionary",
            withAutocomplete: true,
            dictionary: "tools",
            mandatory: true,
            conditionalOn: "toolType",
            conditionalOnNotNull: true,
            filterValues: function (dictionaries, formData, values) {
                if (formData && formData["toolType"]) {
                    return values.filter(t => !t.verified)
                        .filter(t => t.toolType === formData["toolType"])
                }

                return values.filter(t => !t.verified);
            }
        }

    ]


};

export default fields;