const dateUtils = {


  getCurrentDate: (separator = "") => {

    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
  },

  getCurrentDateTime: (separator = "") => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hours = newDate.getHours();
    let minutes = newDate.getMinutes();
    let seconds = newDate.getSeconds();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`
        + ` ${hours < 10 ? `0${hours}` : `${hours}`}:${minutes < 10 ? `0${minutes}` : `${minutes}`}:${seconds < 10 ? `0${seconds}` : `${seconds}`}`;
  },

  isDateBeforeToday: (dateStr, validTime) => {

    const dayFromDate = dateUtils.dayFromDate(dateStr);

    return dayFromDate > validTime;
  },

  dayFromDate: (dateStr) => {
    // Parse the string date into a Date object
    const inputDate = new Date(dateStr);

    // Get the current date
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = currentDate -inputDate;

    // Calculate the number of days (milliseconds / 24 hours / 60 minutes / 60 seconds)
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    console.log(daysDifference);
    return daysDifference;
  }

};


export default dateUtils;
