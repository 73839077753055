import React, {useEffect, useState} from "react";

import CardFormDetails from "../../components/form/CardFormDetails";
import fields from "./config/VerificationToolSetFormConfig";
import CardForm from "../../components/form/CardForm";
import {Actions} from "../../../client/Actions";
import ModalForm from "../../components/form/ModalForm";
import TemplateSelectionFormConfig from "./config/TemplateSelectionFormConfig";

const ToolSetStep = ({verificationId, verification, setLoader, onSaved, templatesExists}) => {


    const [showTemplateModal, setShowTemplateModal] = useState(templatesExists);
    const [selectedTemplate, setSelectedTemplate] = useState();

    const customSave = (form, onSaved) => {
        const verification = {
            id: `${verificationId}`,
            toolSet: form
        };

        Actions.post(`verification`, verification, onSaved);
    };

    const templateSelected = (form, onSaved) => {
        if (form.selectedTemplate) {
            const tmp = form.selectedTemplate
            tmp.templateName = undefined
            tmp.id = undefined
            tmp.creationDate = undefined
            tmp.creationUsername = undefined
            tmp.toolTypesCreation = []
            setSelectedTemplate(form.selectedTemplate)
        }
        onSaved()
        // Actions.post(`verification/${verificationId}/configuration`, configuration, onSaved);
    };

    useEffect(() => setShowTemplateModal(templatesExists), [templatesExists])

    return (
        <>
            {

                verification ?
                    <CardFormDetails
                        setLoader={setLoader}
                        fields={fields}
                        width={12}
                        withoutHeader={true}
                        className={"mt-2 mx-0 px-0"}
                        initialData={verification.toolSet}
                        readonly={true}
                        reverseButtons={true}

                    />
                    : <CardForm
                        setLoader={setLoader}
                        fields={fields}
                        api={"verification"}
                        width={12}
                        className={"mt-2 mx-0 px-0"}
                        withoutHeader={true}
                        customSave={customSave}
                        postSaved={onSaved}
                        createFormDataFrom={selectedTemplate}
                    />
            }

            <ModalForm

                open={showTemplateModal}
                setOpen={setShowTemplateModal}
                shadow={false}
                setLoader={setLoader}
                fields={TemplateSelectionFormConfig}
                title={"Wybierz szablon"}
                formTitle={"Wybierz szablon albo konrynuj bez szablonu"}
                size={"md"}
                buttonsConf={{
                    save: "Wybierz",
                    cancel: "Kontynuj bez szablonu"
                }
                }
                performReload={() => {
                }}
                customSave={templateSelected}
            />
        </>
    );
};


export default ToolSetStep;