export const getJwt = () => {
  return localStorage.getItem('user_jwt');
};

export const getUser = () => {

  var s = localStorage.getItem('user');
  var s = localStorage.getItem('user');

  console.log("user: " + s)

  return s;
};

export const hasPermission = (permission) => {
  const perms = localStorage.getItem('perms');
  console.log("perms: " + perms)
  if (!perms || perms == '') {
    return false;
  } else if (perms.split(',').find(p => p == permission)) {
    return true;
  }
};

export const hasAdminAudit = () => {
  return hasPermission('ADMIN_AUDIT');
};

export const hasAdminMainRead = () => {
  return hasPermission('ADMIN_MAIN_READ');
};

export const hasAdminMainWrite = () => {
  return hasPermission('ADMIN_MAIN_WRITE');
};

export const hasAdminPermissions = () => {
  return hasPermission('ADMIN_PERMISSIONS');
};

export const hasDryersAdd = () => {
  return hasPermission('DRYERS_ADD');
};

export const hasDryersRead = () => {
  return hasPermission('DRYERS_READ');
};

export const hasFiltersAdd = () => {
  return hasPermission('FILTERS_ADD');
};

export const hasFiltersCreateGroup = () => {
  return hasPermission('FILTERS_CREATE_GROUP');
};

export const hasFiltersStorageRead = () => {
  return hasPermission('FILTERS_STORAGE_READ');
};

export const hasFilterSetAccept = () => {
  return hasPermission('FILTER_SET_ACCEPT');
};

export const hasFilterSetManagement = () => {
  return hasPermission('FILTER_SET_MANAGEMENT');
};

export const hasFilterSetRead = () => {
  return hasPermission('FILTER_SET_READ');
};

export const hasGoToProdCreate = () => {
  return hasPermission('GO_TO_PROD_CREATE');
};

export const hasGoToProdManagement = () => {
  return hasPermission('GO_TO_PROD_MANAGEMENT');
};

export const hasGoToProdRead = () => {
  return hasPermission('GO_TO_PROD_READ');
};

export const hasManagementActions = () => {
  return hasPermission('MANAGEMENT_ACTIONS');
};

export const hasManagementLockUnlock = () => {
  return hasPermission('MANAGEMENT_LOCK_UNLOCK');
};

export const hasManagementRead = () => {
  return hasPermission('MANAGEMENT_READ');
};

export const hasManagementWithdraw = () => {
  return hasPermission('MANAGEMENT_WITHDRAW');
};

export const hasProductionCurrent = () => {
  return hasPermission('PRODUCTION_CURRENT');
};

export const hasProductionHistory = () => {
  return hasPermission('PRODUCTION_HISTORY');
};

export const hasProductionReturn = () => {
  return hasPermission('PRODUCTION_RETURN');
};

export const hasReportFilters = () => {
  return hasPermission('REPORT_FILTERS');
};

export const hasReportProduction = () => {
  return hasPermission('REPORT_PRODUCTION');
};

export const hasReportReplacements = () => {
  return hasPermission('REPORT_REPLACEMENTS');
};

export const hasWashingMachineRead = () => {
  return hasPermission('WASHING_MACHINE_READ');
};

export const hasWithdrawnDelete = () => {
  return hasPermission('WITHDRAWN_DELETE');
};

export const hasWithdrawnRead = () => {
  return hasPermission('WITHDRAWN_READ');
};

export const hasWithdrawnRollback = () => {
  return hasPermission('WITHDRAWN_ROLLBACK');
};



export const isSanofi = () => {
  return process.env.REACT_APP_COMPANY == "sanofi"
};





export const authHeader = () => {
  return { headers: { Authorization: `Bearer ${getJwt()}` } };
};

export const paramsSerializer = (params) => {
  return Object.entries(params).
    map(([key, value]) => `${key}=${value}`).
    join('&');
};