const renderUtils = {

    renderDryer: (dryer) => {
        return dryer ?
            renderDictionary(dryer.manufacturer) +
            dryer.model + " " +
            dryer.serialNumber
            : "";
    },

    renderEnumName: (enumValue, enumType) => {
        if (enumType === "enums/filter-kinds") {

            return enumValue === "LEVEL_1" ? "Filtr rodzaju 1 (suszarnia)" : "Filtr rodzaju 2 (port)";
        } else if (enumType === "enums/typeOfSeries") {

            return enumValue === "STANDARD" ? "Standardowa" : "Duża";
        } else if (enumType === "auditActionType") {
            const result = auditActionType[enumValue];

            return result || enumValue;
        } else if (enumType === "enums/matrixOrSegment") {
            const result = matrixOrSegment[enumValue];

            return result || enumValue;
        } else if (enumType === "enums/measurementType") {
            const result = measurementType[enumValue];

            return result || enumValue;
        } else if (enumType === "enums/matrixInputType") {
            const result = matrixInputType[enumValue];

            return result || enumValue;
        } else if (enumType === "enums/toolType") {
            const result = toolType[enumValue];

            return result || enumValue;
        } else if (enumType === "toolTypesCreation") {
            const result = toolTypesCreation[enumValue];

            return result || enumValue;
        } else if (enumType === "dictionaryType") {
            const result = dictionaryType[enumValue];

            return result || enumValue;

        }
    }
};

const renderDictionary = (dictionary) => {
    return dictionary ? dictionary.name + " " : "";

};

const auditActionType = {
    "TOOL_VERIFICATION": "Weryfikacja narzędzia",
    "VERIFICATION_CREATION": "Utworzenie weryfikacji",
    "VERIFICATION_COMPLETE": "Zakończenie weryfikacji",
    "VERIFICATION_CONFIGURATION_UPDATE": "Uzupełnienie konfiguracji",
    "TOOL_SET_TEMPLATE_CREATION": "Dodanie",
    "TOOL_SET_TEMPLATE_REMOVAL": "Usunięcie",
};
const dictionaryType = {
    "MANUFACTURER": "Producent",
    "PRODUCT": "Product",
    "CLEANING_TOOL": "Czyszczenie",
    "SUPPLIER": "Dostawca"
};

const matrixOrSegment = {
    "MATRIX": "Matryca",
    "SEGMENT": "Segment"
};

const measurementType = {
    "WORKING_LENGTH": "Długość robocza",
    "TOTAL_LENGTH": "Długość całkowita"
};

const matrixInputType = {
    "ONE_SIDE": "Jednostronne",
    "DOUBLE_SIDE": "Dwustronne"
};

const toolType = {
    "UPPER": "Górne",
    "LOWER": "Dolne",
    "MATRIX": "Matryca",
    "SEGMENT": "Segment",
};

const toolTypesCreation = {
    "UPPER": "Górne",
    "LOWER": "Dolne",
    "MATRIX_SEGMENT": "Matryca/Segment"
};

export default renderUtils;
