import React, { useState, useEffect } from 'react';
import './AuditComponent.css'
import { Form, Button, Card, Col, Row } from 'react-bootstrap';
import pagesActions from '../../pages/pagesActions';
import Loader from '../../components/Loader';
import { Actions } from "../../../client/Actions";
import { paramsSerializer } from "../../helpers/jwt";

const AuditSearchForm = ({ apiType, storeSetter }) => {
    // loader
    const [loader, setLoader] = useState(false);

    const [isFormValidated, setIsFormValidated] = useState(false);

    const [dateToValue, setDateToValue] = useState('');
    const [dateFromValue, setDateFromValue] = useState('');

    const handleSearchRequest = (e) => {
        e.preventDefault();
        prepareRequest();
    };

    const prepareRequest = () => {
        Actions.get(`/audit/${apiType}?${paramsSerializer({ from: dateFromValue, to: dateToValue })}`, storeSetter)
    };

    useEffect(prepareRequest, [loader]);

    return (
        <>
            <Loader loader={loader} setLoader={setLoader} />
            <Card className="mt-2" >
                <Card.Header className="py-3">Wyszukaj po datach</Card.Header>
                <Card.Body>
                    <Form noValidate className={!isFormValidated ? "form-valid-animation" : "form-invalid-animation"} onSubmit={handleSearchRequest}>
                        <Row>
                            <Col sm="5">
                                <Form.Group as={Row} controlId="delivery_date_control">
                                    <Form.Label column sm="3" className="text-right mt-1"><strong>Data od</strong></Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            // className={deliveryDateError ? "is-invalid" : "is-valid"}
                                            onChange={e => setDateFromValue(e.target.value)}
                                            name="delivery_date"
                                            value={dateFromValue}
                                            type="date"

                                            loca
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col sm="5">
                                <Form.Group as={Row} controlId="delivery_date_control">
                                    <Form.Label column sm="3" className="text-right mt-1 "><strong>Data do</strong></Form.Label>
                                    <Col sm="9">
                                        <Form.Control
                                            onChange={e => setDateToValue(e.target.value)}
                                            name="delivery_date"
                                            value={dateToValue}
                                            type="date"
                                        />
                                    </Col>
                                </Form.Group>
                            </Col>
                            <Col sm="2">
                                <Button type="submit" variant="primary"  >Odśwież</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
}

export default AuditSearchForm;
