import React from 'react';
import { Button, Modal, Col, Form, Row } from 'react-bootstrap';
import FormInputFile from './file/FormInputFile';
import { useState } from 'react';
import pagesActions from '../pages/pagesActions';


const IssueCreationModal = ({ show, setShow }) => {

  const emptyIssue = {
    title: "",
    pageUrl: "",
    comment: "",
    bugImages: []
  };
  const [issue, setIssue] = useState(emptyIssue);

  const [showSuccessModal, setShowSuccessModal] = useState(false);


  const save = () => {
    pagesActions.createIssue(issue, callBackCreateIssue)
  }

  const callBackCreateIssue = (resp) => {
    if (resp.status === "OK") {
      setShow(false);
      setShowSuccessModal(true);
      setIssue(emptyIssue);
    }
  }


  const validateTitle = (value) => {
    setIssue({ ...issue, title: value })
  }

  const setImages = (value) => {
    setIssue({ ...issue, bugImages: value })
  }

  const validateUrl = (value) => {
    setIssue({ ...issue, pageUrl: value })
  }

  const validateDescription = (value) => {
    if (value.length < 1000) {
      setIssue({ ...issue, comment: value })
    } else {
      setIssue({ ...issue, comment: value.substring(0, 999) })
    }
  }


  return (
    <div>
      <Modal show={show} onHide={() => setShow(false)} size="lg">
        <Modal.Header closeButton>
          Nowy błąd
      </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>

              <Form noValidate>
                <Form.Row>
                  <Col>
                    <Form.Group controlId="title_control">
                      <Form.Label><strong>Nazwa</strong></Form.Label>
                      <Form.Control
                        className="is-valid"
                        name="tittle"
                        onChange={e => validateTitle(e.target.value)}
                        value={issue.title}
                        type="text"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group controlId="url_control">
                      <Form.Label><strong>Link do strony</strong></Form.Label>
                      <Form.Control
                        className="is-valid"
                        onChange={e => validateUrl(e.target.value)}
                        name="url"
                        value={issue.pageUrl}
                        type="text"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <Form.Group controlId="description">
                      <Form.Label><strong>Opis</strong></Form.Label>
                      <Form.Control
                        onChange={e => validateDescription(e.target.value)}
                        as="textarea"
                        className="is-valid"
                        name="description"
                        value={issue.comment}
                        type="text"
                        placeholder=""
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <FormInputFile titleText={"Wgraj screenshot'y"}
                  labelText={"Wybierz plik"}
                  idName={"bugImage"}
                  path={"bugImage"}
                  requestParameterName={"bugImage"}
                  setImagesParentStore={setImages}
                  imagesParentStore={issue.bugImages} />

                <Row>
                  <Col>
                    <Button className="float-right" onClick={save}>Zapisz</Button>

                  </Col>
                </Row>
              </Form>
            </Col>

          </Row>
        </Modal.Body>
      </Modal>
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Zapisano.</Modal.Title>
        </Modal.Header>
        <Modal.Body>{"Błąd został poprawnie zgłoszony"}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="Modal_btn_submit"
                  onClick={() => setShowSuccessModal(false)}>Kontynuuj</Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default IssueCreationModal;
