import React, {useState} from 'react';
import './BtnSliderRoundedComponent.css';

const BtnSliderRoundedComponent = ({checked, setState, disabled, red}) => {
    const [isChecked, setChecked] = useState(checked);

    const isUseOnChange = () => {
        if (isChecked) {
            setState(false);
            setChecked(false);
        } else {
            setState(true);
            setChecked(true);
        }
    };


    return (
        <>
            <label className={disabled ? "switch disabled" : "switch"}>
                <input type="checkbox" onChange={isUseOnChange} className={isChecked ? "checked" : ""}
                       checked={!!isChecked} disabled={disabled}/>
                <span className={`slider round ${red || !isChecked ? 'red' : ''}`}></span>
            </label>
        </>
    );
}

export default BtnSliderRoundedComponent;