import React, {useEffect, useState} from "react";
import CustomCard from "../CustomCard";
import FormGenerator from "./FormGenerator";
import {Button, Row} from "react-bootstrap";
import ConfirmModal from "../modal/ConfirmModal";
import {Actions} from "../../../client/Actions";
import IconButton from "../button/IconButton";
import QrCode from "../qrCode/QrCode";

const CardForm = ({
                      setLoader,
                      fields,
                      qrCode,
                      api,
                      title,
                      shadow,
                      performReload,
                      initialData,
                      additional,
                      withoutCancel,
                      customSave,
                      saveOnValid,
                      disableCleanAfterSave,
                      customClean,
                      className,
                      readOnly,
                      onInvalid,
                      postSaved,
                      reverseButtons,
                      saveButton,
                      dictionaries,
                      withoutHeader,
                      noActions,
                      formDataChanged,
                      createFormDataFrom,
                      width = 6
                  }) => {

    const [formData, setFormData] = useState();
    const [ready, setReady] = useState(false);
    const [alertInvalid, setAlertInvalid] = useState(false);
    const [showAllerModal, setShowAllerModal] = useState(false);
    const [showQrCodeModal, setShowQrCodeModal] = useState(false);
    const [qrCodeReady, setQrCodeReady] = useState(false);


    const saveData = () => {
        setLoader(true);
        if (customSave) {
            customSave(formData, onSaved);
        } else {
            Actions.post(api, formData, onSaved);

        }
    };

    const handleSave = () => {
        if (!ready) {
            setAlertInvalid(true);
            if (onInvalid) {
                onInvalid();
            } else {
                setShowAllerModal(true);
            }
        } else {
            saveData();
        }

    };


    const onSaved = () => {

        if (postSaved) {
            postSaved(formData);
        }


        if (customClean) {
            setFormData(customClean(formData));
            setAlertInvalid(false)
        } else if (!disableCleanAfterSave) {
            clearData();
        }

        if (performReload) {
            performReload();
        }

        setLoader(false);

    };

    const onCancel = () => {
        clearData();
    };

    const clearData = () => {
        setFormData(undefined);
        setAlertInvalid(false);
    };

    const calculateQrCodeReady = () => {
        const result = qrCode && formData && formData[qrCode] && formData[qrCode] !== "";

        setQrCodeReady(result);
    };



    useEffect(calculateQrCodeReady, [formData]);
    useEffect(() => {
        if (formDataChanged) {
            formDataChanged(formData)
        }
    }, [formData]);

    useEffect(() => {
        if (saveOnValid && ready) {
            handleSave()
        }
    }, [ready]);

    useEffect(() => {
        if (createFormDataFrom) {
            setFormData(createFormDataFrom)
        }
    }, [createFormDataFrom]);

    function prepareTittle() {
        return <>
            <div className="float-left">
                {title}
            </div>

            {qrCode ?
                <div className="float-right">

                    <IconButton
                        disabled={!qrCodeReady}
                        variant="outline-primary"
                        onClick={() => setShowQrCodeModal(true)}
                        icon="fa-qrcode"
                        tooltip="Kod QR"
                    />

                </div>
                : ""
            }
        </>;
    }

    return (
        <>

            <CustomCard title={prepareTittle()}
                        shadow={shadow}
                        className={className}
                        withoutHeader={withoutHeader}
                        width={width}>
                <FormGenerator fields={fields}
                               formData={formData}
                               initData={true}
                               setFormData={setFormData}
                               setReady={setReady}
                               localDictionaries={dictionaries}

                               alertInvalid={alertInvalid}
                               initialData={initialData}
                               additional={additional}
                               readOnly={readOnly}/>


                {!readOnly && !noActions && !saveOnValid &&
                    <Button type="submit" onClick={handleSave}
                            className={`Save_btn ${ready ? "valid-btn" : "invalid-btn"} ${(withoutCancel || reverseButtons) ? "float-right" : ""}`}
                            variant="primary">{saveButton ? saveButton : "Zapisz"}</Button>
                }
                {!withoutCancel && !readOnly && !noActions &&
                    <Button className={` Reset_btn ${reverseButtons ? "float-left" : "float-right"}`}
                            variant="outline-secondary"
                            onClick={onCancel}

                    >
                        <i className="fas fa-times"/>&nbsp;Anuluj
                    </Button>
                }
            </CustomCard>

            <ConfirmModal
                title={"Nie wszystkie pola zostały uzupełnione"}
                description={"Uzupełnij pola zaznaczone na czerwono."}
                size={"md"}
                show={showAllerModal}
                setShow={setShowAllerModal}
                alert
                onConfirm={() => setShowAllerModal(false)}
            />

            {formData && qrCode && qrCodeReady ?
                <ConfirmModal
                    title={"Kod QR"}
                    size={"md"}
                    show={showQrCodeModal}
                    setShow={setShowQrCodeModal}
                    alert
                    children={

                        <Row className="justify-content-center">
                            <QrCode value={formData[qrCode]} filePrefix={"filter"}/>
                        </Row>

                    }
                />
                : ""}
        </>
    );
};


export default CardForm;