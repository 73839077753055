import * as React from "react";

const fields = {
    config: {columns: 1},
    fields: [
        {
            name: "confirmed",
            label: "Potwierdż że czujnik jest wyzerowany",
            column: 1,
            type: "radio",
            mandatory: true,
            size: {
                label: 10,
                radio: 2
            },
            validate: (value) => {
                return value === true || value === "true";
            }
        }


    ]


};

export default fields;