import React, {useEffect, useState} from "react";


import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import BtnSliderRoundedComponent from "../switch/BtnSliderRoundedComponent";
import {conditionalSuccess, determineDefault, isValid} from "./FormUtils";
import {Actions} from "../../../client/Actions";
import ModalForm from "./ModalForm";
import FormInputFile from "../file/FormInputFile";
import renderUtils from "../../helpers/renderUtils";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Typeahead} from "react-bootstrap-typeahead";
import MeasurementOverlay from "../../pages/verification/MeasurementOverlay";


const FormGenerator = ({
                           fields,
                           initialData,
                           formData,
                           setFormData,
                           setReady,
                           initData,
                           readOnly,
                           alertInvalid,
                           localDictionaries,
                           reload,
                           alertData,
                           additionalDataForFilters,
                           onSubmit
                       }) => {

        const [dictionaries, setDictionaries] = useState({});
        const loaded = {};
        const tempState = {};

        const [validation, setValidation] = useState([]);
        const [showMeasurement, setShowMeasurement] = useState({});
        const [creationModals, setCreationModals] = useState({});
        const [dictionaryDetailsExpanded, setDictionaryDetailsExpanded] = useState({});

        const booleanOptions = [{id: true, name: "Tak"}, {id: false, name: "Nie"}];


        function loadDictionaries() {
            Object.keys(loaded).forEach(key => loaded[key] === false);

            fields.fields.forEach((field) => {

                const {type, readOnlyValue} = field;

                if (readOnly !== true || readOnlyValue) {


                    if (type === "dictionary") {
                        const {dictionary, dictionaryApi} = field;
                        loaded[dictionary] = false;
                        if (localDictionaries && localDictionaries[dictionary]) {
                            const callback = getUpdateDictionaryFunction(dictionary)
                            callback(localDictionaries[dictionary])
                        } else {
                            Actions.get(dictionaryApi ? dictionaryApi : dictionary, getUpdateDictionaryFunction(dictionary));
                        }
                    }

                    if (type === "enum") {
                        const {enumType} = field;
                        loaded[enumType] = false;

                        if (localDictionaries && localDictionaries[enumType]) {
                            const callback = getUpdateDictionaryFunction(enumType)
                            callback(localDictionaries[enumType])
                        } else {
                            Actions.get(enumType ? enumType : enumType, getUpdateDictionaryFunction(enumType));
                        }


                    }
                }
            });

        }


        const getUpdateDictionaryFunction = (dictionary) => {
            return (response) => updateDictionary(dictionary, response);
        };

        const updateDictionary = (dictionary, data) => {

            tempState[dictionary] = data;
            loaded[dictionary] = true;
            if (!Object.keys(loaded).find(key => loaded[key] === false)) {
                setDictionaries(tempState);

                Object.keys(tempState).forEach(key => {

                    if (tempState[key] && Array.isArray(tempState[key]) && tempState[key].find(v => v.defaultValue || v.defaultValue === "true")) {
                        let defaultValue = tempState[key].find(v => v.defaultValue || v.defaultValue === "true");
                        handleFieldChange(key, defaultValue);
                    }
                });
            }


        };

        function reloadData() {
            loadDictionaries();
        }

        function formAction() {
            console.log(formData);
            if (formData && readOnly !== true) {
                validate();
                console.log(formData);
            } else if (initData !== false) {
                initFormData();
            }
        }


        function validate() {
            let validationResult = [];
            let allValid = true;
            fields.fields
                .filter(f => f.type !== "unvisible")
                .filter(f => f.onlyForReadOnly !== true)
                .forEach((field) => {
                    let valid = isValid(field, formData);


                    validationResult[field.name] = valid;
                    if (!valid) {
                        allValid = false;
                    }
                });


            setValidation(validationResult);


            console.log(allValid);
            console.log(validationResult);
            setReady(allValid);
        }


        function initFormData() {

            if (!formData) {

                const result = {};
                fields.fields
                    .filter(f => f.onlyForReadOnly !== true)
                    .forEach((field) => {
                        result[field.name] = determineDefault(field, initialData, dictionaries);
                    });
                setFormData(result);
            }
        }

        useEffect(formAction, [formData]);
        useEffect(reloadData, [reload, localDictionaries]);
        useEffect(loadDictionaries, []);


        const handleFieldChange = (fieldName, value) => {
            setFormData({
                ...formData,
                [fieldName]: value
            });
        };

        const handleCheckboxGroupChange = (fieldName, value) => {

            let current = formData[fieldName];


            if (current.includes(value)) {
                const result = current.filter(v => v !== value)

                handleFieldChange(fieldName, result)
            } else {
                current.push(value)
                handleFieldChange(fieldName, current)
            }

        };

        const handleModalChange = (fieldName, value) => {
            setCreationModals({
                ...creationModals,
                [fieldName]: value
            });
        };


        const collapseDictionary = (fieldName) => {
            setDictionaryDetailsExpanded({
                ...dictionaryDetailsExpanded,
                [fieldName]: !dictionaryDetailsExpanded[fieldName]
            });
        };

        function fetchValueFromDictionary(triggerValue, triggerField) {
            if (triggerValue) {
                let triggerFieldDef = fields.fields.find(f => f.name === triggerField);
                if (triggerFieldDef && triggerFieldDef.type === "dictionary" && dictionaries[triggerFieldDef.dictionary]) {
                    triggerValue = dictionaries[triggerFieldDef.dictionary].find(d => d.id == triggerValue.id);
                }
            }
            return triggerValue;
        }

        function fillFieldsToUpdate(field, updated, result) {
            const filtered = fields.fields
                .filter(f => !updated.includes(f.name)
                    && f.autoComplete
                    && f.autoComplete.triggers
                    && f.autoComplete.triggers.includes(field)
                );


            filtered.forEach(f => {
                const index = f.autoComplete.triggers.indexOf(field);
                let calculated;


                if (f.autoComplete.triggers.length === 1) {
                    const triggerField = f.autoComplete.triggers[0];


                    let triggerValue = result[triggerField] ? result[triggerField] : formData[triggerField];
                    triggerValue = fetchValueFromDictionary(triggerValue, triggerField);

                    console.log(triggerValue);
                    calculated = f.autoComplete.calculation(triggerValue);
                } else if (index === 0) {
                    const secondField = f.autoComplete.triggers[1];
                    const secondValue = result[secondField] ? result[secondField] : formData[secondField];
                    calculated = f.autoComplete.calculation(result[field], secondValue);
                } else {
                    const firstField = f.autoComplete.triggers[0];
                    const firstValue = result[firstField] ? result[firstField] : formData[firstField];
                    calculated = f.autoComplete.calculation(firstValue, result[field]);
                }
                result[f.name] = calculated;

                updated.push(f.name);
            });

            filtered.forEach(f => {
                fillFieldsToUpdate(f.name, updated, result);
            });

            if (result[field] === "false") {
                const conditional = fields.fields
                    .filter(f => !updated.includes(f.name)
                        && f.conditionalOn
                        && f.conditionalOn === field
                    );

                conditional.forEach(f => {
                    result[f.name] = determineDefault(f, initialData, dictionaries);

                    updated.push(f.name);
                });
            }


            const clearAfterChange = fields.fields
                .filter(f => !updated.includes(f.name)
                    && f.clearAfterChange === field
                );

            clearAfterChange.forEach(f => {
                result[f.name] = determineDefault(f, initialData, dictionaries);

                updated.push(f.name);
            });


        }

        const handleMultipleFieldsChange = (fieldName, value) => {
            const updateFileds = [fieldName];
            const result = {[fieldName]: value};
            fillFieldsToUpdate(fieldName, updateFileds, result);

            setFormData({
                ...formData,
                ...result
            });
        };

        function defineClass(field) {
            if (readOnly) {
                return "";
            }
            return validation[field.name] ? "is-valid" : "is-invalid";
        }

        function textField(field, value) {


            return (
                <>
                    {
                        field.alert && field.alert.condition(value || formData[field.name], alertData) &&
                        <Alert variant="danger"><i
                            className="fas fa-info-circle mr-1"></i><strong> {field.alert.title}</strong></Alert>
                    }
                    <Form.Group>


                        <Form.Label><strong>{field.label}</strong></Form.Label>
                        <Form.Control
                            as={field.type === "textarea" ? field.type : "input"}
                            className={defineClass(field)}
                            disabled={field.readOnly || readOnly}
                            id={field.name}

                            onChange={(event) => {
                                let newValue = event.target.value;
                                if (field.type === "decimal" && newValue !== "") {
                                    newValue = parseFloat(parseFloat(newValue).toFixed(2));
                                }

                                if (field.format && newValue !== "") {
                                    newValue = field.format(newValue);
                                }

                                if (field.capsLock && newValue !== "") {
                                    newValue = newValue.toUpperCase();
                                }

                                if (field.type === "number" && newValue !== "") {
                                    if (field.scale) {
                                        const regex = new RegExp("^\\d+(\\.\\d{0," + field.scale + "})?$");
                                        if (!regex.test(newValue)) {
                                            // If the value does not match the pattern, return or handle the error
                                            return;
                                        }
                                    } else if (!/^[^\D_]+$/.test(newValue)) {
                                        return;
                                    }


                                }


                                handleMultipleFieldsChange(field.name, newValue);

                            }}
                            name={field.name}
                            value={value || formData[field.name]}
                            type="text"
                            placeholder={field.placeholder}
                        />
                    </Form.Group>
                </>
            );


        }

        function measurement(field) {

            function updateShowMeasurement(val) {
                setShowMeasurement({
                    ...showMeasurement,
                    [field.name]: val
                })
            }

            return (
                <>


                    <MeasurementOverlay show={showMeasurement[field.name]}
                                        setValue={(value) => {
                                            let newValue = value.replace(',', '.');
                                            const regex = new RegExp("^\\d+(\\.\\d{0," + 2 + "})?$");
                                            if (!regex.test(newValue)) {
                                                return;
                                            }

                                            if (field.filterTargetValue) {
                                                newValue = field.filterTargetValue(newValue);
                                            }


                                            handleMultipleFieldsChange(field.name, newValue)
                                        }}
                                        setShow={updateShowMeasurement}
                    />

                    <Form.Group>
                        <Form.Label><strong>{field.label}</strong></Form.Label>
                        <Row>
                            <Col md={readOnly ? 12 : 8}>
                                <Form.Control
                                    as={"input"}
                                    disabled={true}
                                    id={field.name}
                                    onChange={(event) => {
                                        let newValue = event.target.value;

                                        const regex = new RegExp("^\\d+(\\.\\d{0," + 2 + "})?$");
                                        if (!regex.test(newValue)) {
                                            return undefined;
                                        }

                                        if (field.filterTargetValue) {
                                            newValue = field.filterTargetValue(field);
                                        }


                                        handleMultipleFieldsChange(field.name, newValue);
                                    }}
                                    name={field.name}
                                    value={formData[field.name]}
                                    type="text"
                                    placeholder={field.placeholder}
                                />
                            </Col>
                            {!readOnly &&
                                <Col md={4}>
                                    <Button
                                        onClick={() => updateShowMeasurement(true)}
                                        variant={"outline-primary float-center"}>Pomiar</Button>
                                </Col>
                            }
                        </Row>

                    </Form.Group>
                </>
            );


        }

        function datePicker(field) {


            return (
                <>
                    <Form.Group>
                        <Form.Label><strong>{field.label}</strong></Form.Label>
                        <Form.Control
                            className={defineClass(field)}
                            disabled={readOnly}
                            id={field.name}

                            onChange={(event) => {
                                let newValue = event.target.value;
                                handleFieldChange(field.name, newValue);
                            }}
                            name={field.name}
                            value={formData[field.name]}
                            type="date"
                        />
                    </Form.Group>
                </>
            );


        }

        function file(field) {


            return (
                <>
                    <FormInputFile
                        titleText={field.label}
                        labelText={"Wybierz plik"}
                        idName={field.name}
                        path={"file"}
                        requestParameterName={"file"}
                        setImagesParentStore={(files) => handleFieldChange(field.name, files)}
                        imagesParentStore={formData[field.name]}
                        disabled={readOnly}
                    />
                </>
            );


        }


        function boolean(field) {

            if (readOnly) {

                const bool = booleanOptions.find(v => v.id === formData[field.name]);
                const value = bool ? bool.name : "";

                return textField(field, value);
            }

            return (
                <>

                    <Form.Group>
                        <Form.Label><strong>{field.label}</strong></Form.Label>
                        <Form.Control as="select" name={field.name}
                                      if={field.name}
                                      value={formData[field.name]}
                                      disabled={readOnly || field.readOnly}
                                      className={defineClass(field)}
                                      onChange={(event) => {
                                          let newValue = event.target.value;

                                          handleMultipleFieldsChange(field.name, newValue);

                                      }}>
                            <option value="" disabled>Wybierz...</option>
                            {
                                booleanOptions.map((el => {
                                    return <option key={el.id} value={el.id}>{el.name}</option>;
                                }))
                            }
                        </Form.Control>
                    </Form.Group>

                </>
            );


        }


        function renderDictionaryName(field, dictionaryValue) {
            return field.render ? field.render(dictionaryValue) : dictionaryValue.name;
        }

        function dictionary(field) {
            const {dictionaryApi, dictionary, groupedDictionary} = field;
            let values = dictionaries[dictionary] || [];


            if (readOnly) {

                const dictionaryValue = formData[field.name];
                let value = dictionaryValue ? renderDictionaryName(field, dictionaryValue) : "";
                if (!value || value === "") {
                    if (field.readOnlyValue) {
                        value = field.readOnlyValue(values);
                    }
                }
                return textField(field, value ? value : "");
            }

            if (field.filterValues) {
                values = field.filterValues(dictionaries, formData, values, additionalDataForFilters);
            }

            console.log(values)

            const filterBy = (option, props) =>
                String(option.toolNumber).toLowerCase().indexOf(props.text.toLowerCase()) !== -1 ||
                option.labelling.toLowerCase().indexOf(props.text.toLowerCase()) !== -1;

            return <>
                <Form.Group>
                    <Form.Label><strong>{field.label}</strong></Form.Label>
                    {field.creationForm ?
                        <div className="text-secondary" id={"open-modal" + field.name}
                             onClick={() => handleModalChange(field.name, true)}>
                            <i className="fas fa-plus-circle" style={{marginLeft: "5px", fontSize: "15px"}}/>
                        </div> : ""}
                    {field.withAutocomplete ?

                        <Typeahead
                            name={field.name}
                            id={field.name}
                            labelKey="labelling"

                            disabled={readOnly || field.readOnly}
                            options={values}
                            filterBy={filterBy}
                            placeholder="Wpisz numer narzędzia"
                            // selected={formData[field.name] ? formData[field.name] : null}
                            className={defineClass(field)}
                            renderMenuItemChildren={(option) => (
                                <div>
                                    {`${option.toolNumber} ${option.labelling}`}
                                </div>
                            )}
                            onChange={(value) =>
                                handleMultipleFieldsChange(field.name, value[0])
                            }
                        />
                        : <Form.Control as="select"
                                        name={field.name}
                                        id={field.name}
                                        disabled={readOnly || field.readOnly}
                                        value={formData[field.name] ? formData[field.name].id : ""}
                                        className={defineClass(field)}
                                        onChange={(event) => {
                                            if (field.selectObject) {
                                                handleMultipleFieldsChange(field.name, values.find(v => v.id === parseInt(event.target.value)))

                                            } else {
                                                handleMultipleFieldsChange(field.name, {id: parseInt(event.target.value)})
                                            }
                                        }}>
                            <option value="" disabled>Wybierz...</option>
                            {
                                groupedDictionary ?
                                    Object.keys(values).map((item, i) => (
                                        <>
                                            <optgroup key={item + i} label={item}>
                                                {
                                                    values[item].map((item, index) => {
                                                        return <option key={item.id}
                                                                       value={item.id}>{item.name}</option>
                                                    })
                                                }
                                            </optgroup>
                                        </>
                                    ))
                                    :
                                    values.map((val => {
                                        return <option key={val.id}
                                                       value={val.id}>{renderDictionaryName(field, val)}</option>;
                                    }))
                            }
                        </Form.Control>
                    }
                </Form.Group>

                {field.creationForm ?
                    <ModalForm
                        setLoader={() => {
                        }}
                        api={dictionaryApi ? dictionaryApi : dictionary}
                        title={field.modalTitle ? field.modalTitle : "Nowy " + field.label}
                        open={creationModals[field.name]}
                        setOpen={(value) => handleModalChange(field.name, value)}
                        fields={field.creationForm}
                        onFinish={reloadData}

                    />
                    : ""}

            </>;
        }


        function enumType(field) {
            const {enumType} = field;
            let values = dictionaries[enumType] || [];

            if (field.filterValues) {
                values = field.filterValues(dictionaries, formData, values, additionalDataForFilters);
            }

            if (readOnly) {

                const enumValue = formData[field.name];
                const value = enumValue ? renderUtils.renderEnumName(enumValue, enumType) : "";
                return textField(field, value ? value : "");
            }


            return <>
                <Form.Group>
                    <Form.Label><strong>{field.label}</strong></Form.Label>
                    <Form.Control as="select"
                                  name={field.name}
                                  id={field.name}
                                  disabled={readOnly}
                                  value={formData[field.name] ? formData[field.name].id : ""}
                                  className={defineClass(field)}
                                  onChange={(event) =>
                                      handleFieldChange(field.name, event.target.value)
                                  }>
                        <option value="" disabled>Wybierz...</option>
                        {
                            values.map((value => {
                                return <option key={value}
                                               value={value}>{renderUtils.renderEnumName(value, enumType)}</option>;
                            }))
                        }
                    </Form.Control>
                </Form.Group>
            </>;
        }

        function dictionaryDetails(field) {
            const value = formData[field.name];
            const keys = value ? Object.keys(value) : [];


            return <>
                <Form.Group>


                    <Form.Label><strong>{field.label}</strong></Form.Label>

                    <Form.Row>
                        <Col className="d-flex">
                            <Form.Control
                                name={field.name + "-name"}
                                value={value ? value.name : ""}
                                readOnly={true}
                                type="text"
                                placeholder=""
                            />
                            {keys.length > 2 ?
                                <Button variant="outline-primary" onClick={() => collapseDictionary(field.name)}
                                        id={"collapse-" + field.name} data-toggle="collapse" role="button"
                                        className="collapsed"
                                        type="button" data-target={"#collapse-" + field.name} aria-expanded="false">
                                    <i className="fas fa-chevron-down"/>
                                </Button>
                                : ""
                            }
                        </Col>
                    </Form.Row>


                </Form.Group>


            </>;
        }


        function radioButton(field) {
            const {size} = field
            return <>
                <Row className={"justify-content-center"}>
                    <Col md={size ? size.label : "5"}>
                        <strong className={"p-2"}>{field.label}</strong>

                    </Col>
                    <Col md={size ? size.radio : "32"}>
                        <BtnSliderRoundedComponent checked={formData[field.name]}
                                                   setState={() => handleFieldChange(field.name, !formData[field.name])}
                                                   disabled={readOnly} red={false}/>
                    </Col>

                </Row>
            </>;
        }

        function checkGroup(field) {
            const {options, enumType, name, label} = field;
            const type = "checkbox"
            return <>
                <Form.Group>
                    <Form.Label><strong>{label}</strong></Form.Label>

                    <div className={"d-flex "}>
                        {options.map((option, i) =>


                            <div id={"checkbox-" + name + "-" + i} className="d-flex mr-5">
                                <label className={"checkbox-container checkGroupItem " + defineClass(field)}>
                                    <input type="checkbox"
                                           checked={formData[name].includes(option)}
                                           onChange={() => handleCheckboxGroupChange(name, option)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                <span>{renderUtils.renderEnumName(option, enumType)}</span>

                            </div>
                        )
                        }
                    </div>
                </Form.Group>
            </>;
        }

        function checkbox(field) {
            return <>
                <div className={"d-flex"}>
                    <strong>{field.label}</strong>

                    <div id={"checkbox-" + field.name} className="">
                        <label className="checkbox-container">
                            <input type="checkbox" onChange={() => handleFieldChange(field.name, !formData[field.name])}/>
                            <span className="checkmark"></span>
                        </label>
                    </div>


                </div>
            </>;
        }


        function determineField(field) {
            if (field.type === "dictionary") {
                return dictionary(field);
            }
            if (field.type === "measurement") {
                return measurement(field);
            }

            if (field.type === "enum") {
                return enumType(field);

            }

            if (field.type === "date") {
                return datePicker(field);

            }

            if (field.type === "file") {
                return file(field);

            }

            if (field.type === "radio") {
                return radioButton(field);

            }

            if (field.type === "checkGroup") {
                return checkGroup(field);

            }

            if (field.type === "boolean") {
                return boolean(field);

            }
            if (field.type === "checkbox") {
                return checkbox(field);

            }

            return textField(field);
        }


        function generateForm() {
            const columns = fields.config && fields.config.columns ? fields.config.columns : 1;

            const blocks = [];

            // eslint-disable-next-line no-plusplus
            for (let i = 1; i <= columns; i++) {
                blocks.push(i);
            }

            return <Form noValidate
                         onSubmit={onSubmit ? onSubmit : () => {
                         }}
                         className={alertInvalid ? "form-invalid-animation" : ""}>

                {blocks.length > 1 ?
                    <Row>{
                        blocks.map(i => {
                            return <Col xs={12} sm={blocks.length > 1 ? 6 : 12} md={12 / blocks.length}>
                                {generateColumn(i)}
                            </Col>;
                        })}
                    </Row>
                    :
                    <>
                        {generateColumn(1)}
                    </>
                }


            </Form>;
        }

        function generateColumn(i) {

            return fields.fields
                .filter(f => f.column === i || !f.column && i === 1)
                .map((field) => {

                        let show = field.type !== "unvisible" && (readOnly === true ? field.hideForReadOnly !== true : field.onlyForReadOnly !== true);

                        if (field.conditionalOn) {

                            show = conditionalSuccess(field, formData);
                        }

                        if (show) {
                            return determineField(field);
                        }
                    }
                );

        }


        return (
            <>

                {formData ? generateForm() : ""}


            </>
        );
    }
;

export default FormGenerator;