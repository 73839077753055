import React, {useState} from "react";
import FormGenerator from "./FormGenerator";
import ConfirmModal from "../modal/ConfirmModal";
import {Actions} from "../../../client/Actions";
import {Card, Col, Row} from "react-bootstrap";
import QrCode from "../qrCode/QrCode";

const ModalForm = ({
                       setLoader,
                       qrCode,
                       open,
                       setOpen,
                       size,
                       fields,
                       api,
                       initialData,
                       additional,
                       initFormData,
                       title,
                       closable,
                       readOnly,
                       formTitle,
                       alertData,
                       customSave,
                       onFinish,
                       buttonsConf,
                       additionalDataForFilters
                   }) => {

    const [formData, setFormData] = useState(initFormData);
    const [ready, setReady] = useState(false);
    const [alertInvalid, setAlertInvalid] = useState(false);


    const saveData = () => {
        setLoader(true);

        if (customSave) {
            customSave(formData, onSaved)
        } else {
            Actions.post(api, formData, onSaved);
        }
    };

    const onSaved = (response) => {
        setLoader(false);
        setFormData(undefined);

        if(open){
            setOpen(false)
        }

        if (onFinish) {
            onFinish(response);
        }

    };

    const handleSave = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (!ready) {
            setAlertInvalid(true);
        } else {
            saveData();
        }
    };

    const onCancel = () => {
        setFormData(undefined);
    };


    function form() {

        return <> <FormGenerator fields={fields}
                                 formData={formData}
                                 initData={readOnly}
                                 initialData={initialData}
                                 setFormData={setFormData}
                                 additional={additional}
                                 onSubmit={handleSave}
                                 setReady={setReady}
                                 alertData={alertData}
                                 alertInvalid={alertInvalid}
                                 readOnly={readOnly}
                                 additionalDataForFilters={additionalDataForFilters}
        />
        </>;
    }

    function prepareForm() {

        if (additional) {
            return <>
                <Row>
                    <Col md={6}>
                        <Card className={"m-2 mb-4"}>
                            <Card.Header>
                                {formTitle}
                            </Card.Header>
                            <Card.Body>
                                {form()}
                            </Card.Body>
                        </Card>
                    </Col>
                    {qrCode && formData && formData[qrCode] &&
                        < Col md={6}>
                            <Card className={"m-2 mb-4"}>
                                <Card.Body>
                                    <div className={"justify-content-center d-flex"}>
                                        <QrCode value={formData[qrCode]} filePrefix={"filterSet"}/>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Col>}
                </Row>
                {additional}
            </>;
        } else return form();

    }


    return (
        <>

            <ConfirmModal
                title={title}
                show={open}
                ready={readOnly ? true : ready}
                size={size}
                closable={closable}
                alert={readOnly}
                setShow={setOpen}
                onCancel={onCancel}
                onConfirm={handleSave}
                buttonsConf={buttonsConf}
                form={
                    prepareForm()

                }
            />


        </>
    );
};


export default ModalForm;