import * as React from "react";
import {Button} from "react-bootstrap";
import IconButton from "../../../components/button/IconButton";
import {Actions} from "../../../../client/Actions";


const columns = [

    {
        label: "ID",
        field: "id",
        sort: "desc",
        width: 53,
    },
    {
        label: "Data utworzenia",
        field: "creationDate",
        sort: "desc",
        width: 53,
    },
    {
        label: "Numer wewnętrzny",
        field: "internalNumber",
        sort: "desc",
        width: 53,
        parent: "toolSet"
    },
    {
        field: "toolLabelling",
        label: "Znakowanie narzędzi",
        sort: "desc",
        width: 53,
        parent: "toolSet"

    },
    {
        field: "productName",
        label: "Nazwa produktu",
        sort: "desc",
        width: 53,
        type: "dictionary",
        parent: "toolSet"
    },
    {
        field: "toolsCount",
        label: "Liczba mierzonych narzędzi",
        sort: "desc",
        width: 53,
        parent: "toolSet"
    },
    {
        field: "multipleTool",
        label: "Narzędzia wielokrotne",
        sort: "desc",
        width: 53,
        type: "boolean",
        parent: "toolSet"
    },

    {
        field: "completed",
        label: "Zakończono",
        sort: "desc",
        width: 53,
        type: "custom",
        custom: function (column, row, history) {
            return <>
                {row.completed ?
                    <i className="far fa-check-circle text-primary"></i> :
                    <i className="far fa-times-circle text-secondary"></i>
                }</>
        }

    },

    {
        field: "actions",
        label: "Akcje",
        sort: "desc",
        width: 53,
        type: "custom",
        custom: function (column, row, history) {
            return <div className={"d-flex"}>
                <IconButton
                    variant={"outline-primary"}
                    className="btn-circle ml-1"
                    onClick={() => {
                        history.push("/verification/" + row.id);
                    }}
                    size={"sm"}

                    tooltip={!row.completed ? "Kontynuj" : "Szczegóły"}
                    icon={"fa-arrow-right "}
                />
                {row.completed &&
                    <IconButton
                        icon={"fa-scroll"}
                        variant={"outline-primary"}
                        size={"sm"}
                        className="btn-circle ml-1"
                        onClick={() => {
                            Actions.download(`generate-pdf/measurement/${row.id}`, `verification-${row.id}`);
                        }}
                        tooltip={"Pobierz raport"}
                    />
                }
            </div>
            // <Button as="a" size={"sm"} variant="outline-primary" className="btn-circle ml-1 tableInfoBtn"
            //                role="button"
            //                onClick={() => {
            //                    history.push("/verification/" + row.id);
            //                }}>
            //     <i className="fas fa-arrow-right text-primary"/>
            // </Button>;
        }

    }


];

export default columns;