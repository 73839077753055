export const MockActions = {




  get: (path, callback) => {

    if(path === "/production/3/series"){
      callback(series);

      return true;
    }else {
      return false;
    }
  },

};

export const series = [

  {
    releaseDate: "23-03-2003",
    user: "test",
    finished: false,
    performanceExceeded: true,
    comment: "Basdasdsadas"
  },




]

