import React, {useState} from "react";
import {Loader} from "react-bootstrap-typeahead";
import CustomContainer from "../../../components/CustomContainer";
import CardTable from "../../../components/table/CardTable";
import columns from "./VerificationTemplateTableConfig";

const VerificationPage = (props) => {

    const [loader, setLoader] = useState(false);


    return (
        <>
            <Loader loader={loader}/>
            <CustomContainer title={"Szablony weryfikacji"}
                             className={"px-4"}>


                <CardTable
                    tittle={"Lista szablonów weryfikacji"}
                    source={"toolSetTemplate"}
                    columns={columns}
                    width={12}
                    setLoader={setLoader}
                    striped={false}
                    shadow={true}
                />
            </CustomContainer>
        </>
    );
};

export default VerificationPage;
