import * as React from "react";
import utils from "../../../helpers/utils";
import IconButton from "../../../components/button/IconButton";
import ConfirmIconButton from "../../../components/button/ConfirmIconButton";


const columns = [
    {
        field: "fileName",
        label: "Nazwa",
        sort: "desc",
        wrap: true,
        width: 53,
    },
    {
        field: "fileDescription",
        label: "Opis",
        sort: "desc",
        type: "textarea",
        width: 53,
    },
    {
        field: "actions",
        label: "Akcje",
        sort: "desc",
        width: 53,
        type: "custom",
        custom: function (column, row, history, reload, setReload, setLoader, disableRule, readOnly, actions) {
            return <div className={"d-flex"}>
                {!readOnly &&
                    <ConfirmIconButton

                        className={"btn-circle"}
                        variant="outline-danger"
                        icon="fa-trash"
                        size={"sm"}
                        tooltip={"Usuń"}
                        confirmTitle={"Usunięcie zdjęcia"}
                        confirmDescription={"Czy napewno chcesz usunąć zdjęcie?"}
                        onConfirm={() => {
                            actions.remove(row.id)
                        }}

                        modalSize={'md'}
                    />
                }
                <IconButton variant={"primary"}
                            icon={"fa-arrow-left"}
                            className={"btn-circle ml-1 "}
                            size={"sm"}
                            tooltip={"Otwórz"}
                            onClick={() => {
                                window.open(utils.defaultUrl() + row.filePath, "_blank", "width=1100,height=400px,resizable=1");
                            }}
                />
            </div>

        }
    }


];

export default columns;