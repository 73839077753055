import * as React from "react";

const fields = {
    config: {columns: 1},
    fields: [
        {
            name: "headOk",
            label: "Głowa",
            column: 1,
            defaultValue: "true",

            type: "radio"
        }, {
            name: "stemOk",
            label: "Trzon",
            column: 1,
            defaultValue: "true",

            type: "radio"
        }, {
            name: "tipOk",
            label: "Tip",
            column: 1,
            defaultValue: "true",

            type: "radio"
        }


    ]


};

export default fields;