import React from "react";
import {Card, Col} from "react-bootstrap";
import Table from "./Table";

const CardTable = (props) => {


    return (
        <>
            <Col md={props.width} className={props.className}>
                <Card className={props.shadow === false ? "" : "shadow"}>
                    {
                        props.withoutHeader ? "" : <Card.Header className="py-3">
                            <p className="m-0 font-weight-bold">{props.tittle}</p>
                        </Card.Header>
                    }

                    <Card.Body>
                        {
                            props.columns ?
                                <Table
                                    setLoader={props.setLoader}
                                    source={props.source}
                                    columns={props.columns}
                                    reload={props.reload}
                                    additionalFilter={props.additionalFilter}
                                    checked={props.checked}
                                    readOnly={props.readOnly}
                                    disableRule={props.disableRule}
                                    setChecked={props.setChecked}
                                    initData={props.initData}
                                    striped={props.striped}
                                    pagination={props.pagination}
                                    conditionalRowStyles={props.conditionalRowStyles}
                                    actions={props.actions}
                                /> : ""
                        }

                        {props.children}
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default CardTable;