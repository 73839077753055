import * as React from "react";

const auditConfig = {
    defaultTab: "verification",
    tabs: [
        {
            title: "Weryfikacja",
            type: "verification",
            additionalFields: [
                {
                    label: "ID",
                    field: "verificationId",
                    width: 200
                }
            ],
            infoAction: {
                redirectTo: function (verification) {
                    return `/#/verification/${verification.verificationId}`;
                }
            }

        },
        {
            title: "Szablony weryfikacji",
            type: "toolSetTemplate",
            additionalFields: [
                {
                    label: "ID",
                    field: "templateId",
                    width: 200
                }, {
                    label: "Nazwa",
                    field: "templateName",
                    width: 200
                }
            ]
        },
        {
            title: "Weryfikacja narzędzi",
            type: "tool",
            additionalFields: [
                {
                    label: "ID weryfikacji",
                    field: "verificationId",
                    width: 200
                },
                {
                    label: "Numer narzędzia",
                    field: "toolNumber",
                    width: 200
                },
                {
                    label: "Typ narzędzia",
                    field: "toolType",
                    width: 200,
                    type: "enum",
                    enumType: "enums/toolType",
                }

            ],
            infoAction: {
                redirectTo: function (tool) {
                    return `/#/verification/${tool.verificationId}/?tool=${tool.toolId}`;
                }
            }

        }
    ]

};

export default auditConfig;