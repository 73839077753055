import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ConfirmModal from "../modal/ConfirmModal";


const ConfirmButton = ({
                         confirmTitle,
                         confirmDescription,
                         onConfirm,
                         variant,
                         children,
                         className,
                         form = false,
                         canConfirm = true,
                         disabled,
                         additionalFields,
                         modalSize = "sm"
                       }) => {

  const [show, setShow] = useState(false);

  const onClick = () => {
    setShow(true);
  };

  return (
    <>
      <Button variant={variant}
              className={className}
              disabled={disabled}
              onClick={onClick}>{children}</Button>
      <ConfirmModal
        title={confirmTitle}
        description={confirmDescription}
        show={show}
        setShow={setShow}
        onConfirm={onConfirm}
        additionalFields={additionalFields}
        size={modalSize}
        ready={canConfirm}
        form={form}
      />
    </>
  );
};

export default ConfirmButton;