import * as React from "react";
import SimpleDictionaryFormConfig from "../../../components/common/SimpleDictionaryFormConfig";

const fields = {
    config: {columns: 2},
    fields: [
        {
            name: "productName",
            label: "Nazwa produktu",
            column: 1,
            mandatory: true,
            type: "dictionary",
            dictionary: "productName",
            creationForm: SimpleDictionaryFormConfig

        },
        {
            name: "internalNumber",
            label: "Numer wewnętrzny",
            column: 1,
        },
        {
            name: "supplier",
            label: "Dostawca",
            column: 1,
            type: "dictionary",
            dictionary: "supplier",
            creationForm: SimpleDictionaryFormConfig
        },
        {
            name: "toolStandard",
            label: "Standard narzędzi",
            column: 1,
            mandatory: true,
            type: "dictionary",
            dictionary: "toolStandard"
        },
        {
            name: "multipleTool",
            label: "Narzędzia wielokrotne",
            column: 1,
            type: "boolean",
            mandatory: true
        },
        {
            name: "tipsCount",
            label: "Ilość tipów na stemplu",
            column: 1,
            type: "number",
            mandatory: true,
            conditionalOn: "multipleTool"
        },
        {
            name: "toolsCount",
            label: "Ilość mierzonych narzędzi",
            column: 1,
            type: "number",
            mandatory: true
        },

        {
            name: "toolLabelling",
            label: "Znakowanie narzędzi",
            mandatory: true,
            column: 1,
        },
        {
            name: "toolTypesCreation",
            label: "Co będzie oceniane?",
            column: 1,
            type: "checkGroup",
            enumType: "toolTypesCreation",
            options: ["UPPER", "LOWER", "MATRIX_SEGMENT"],
            mandatory: true,
            validate: (value) => {
                return value && value.length > 0;
            }
        },
        {
            name: "steelType",
            label: "Rodzaj stali do stempli",
            column: 2,
            type: "dictionary",
            dictionary: "steelType",
            groupedDictionary: true,
            creationForm: SimpleDictionaryFormConfig
        },
        {
            name: "withCover",
            label: "Czy jest powłoka?",
            column: 2,
            type: "boolean",
            mandatory: true
        },
        {
            name: "cover",
            label: "Jaka została zastosowana Powłoka?",
            column: 2,
            type: "dictionary",
            dictionary: "cover",
            creationForm: SimpleDictionaryFormConfig,
            conditionalOn: "withCover"
        },
        {
            name: "withUpperWedge",
            label: "Klin prowadzący na stemplu górnym",
            column: 2,
            type: "boolean",
        },
        {
            name: "withLowerWedge",
            label: "Klin prowadzący na stemplu dolnym",
            column: 2,
            type: "boolean",
        },
        {
            name: "matrixOrSegment",
            label: "Matryca czy segment",
            column: 2,
            mandatory: true,
            type: "enum",
            enumType: "enums/matrixOrSegment",
        },
        {
            name: "segmentsCount",
            label: "Ilość segmentów",
            column: 2,
            type: "number",
            mandatory: true,
            conditionalOn: "matrixOrSegment",
            conditionalOnMatchValue: "SEGMENT"
        },
        {
            name: "segmentSteelType",
            label: "Rodzaj stali",
            column: 2,
            conditionalOn: "matrixOrSegment",
            conditionalOnMatchValue: "SEGMENT"
        },
        {
            name: "matrixSteelType",
            label: "Rodzaj stali do Matryc",
            column: 2,
            type: "dictionary",
            dictionary: "matrixSteelType",
            creationForm: SimpleDictionaryFormConfig,
            conditionalOn: "matrixOrSegment",
            conditionalOnMatchValue: "MATRIX"
        },
        {
            name: "matrixWithInput",
            label: "Czy matryca posiada wejście?",
            column: 2,
            type: "boolean",
            conditionalOn: "matrixOrSegment",
            conditionalOnMatchValue: "MATRIX"
        },
        {
            name: "matrixInputType",
            label: "Jakie?",
            column: 2,
            type: "enum",
            enumType: "enums/matrixInputType",
            conditionalOn: "matrixWithInput"
        },
        {
            name: "additionalDocuments",
            label: "Dokumenty dodatkowe",
            type: "file",
            column: 2,
        },
        {
            name: "additionalInformation",
            label: "Informacje dodatkowe",
            type: "textarea",
            column: 2,
        },
        {
            name: "template",
            label: "Zapisać jako szablon?",
            column: 2,
            mandatory: true,
            hideForReadOnly: true,
            type: "boolean"
        },
        {
            name: "templateName",
            label: "Nazwa szablonu",
            column: 2,
            mandatory: true,
            hideForReadOnly: true,
            conditionalOn: "template"

        },


    ]


};

export default fields;