import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import ToolsSection from "./ToolsSection";
import ToolSelectionSection from "./ToolSelectionSection";
import ToolVerification from "./ToolVerification";
import {useHistory} from "react-router-dom";

const MeasurementsStep = ({toolId, verificationId, verification,  confirmed, setConfirmed,setLoader, reload, setReload}) => {

    const history = useHistory()

    const [toolInVerification, setToolInVerification] = useState();
    const [readMode, setReadMode] = useState(false);

    const toolSavedExternal = () => {
        setToolInVerification(undefined);
        setReload(!reload)
        setReadMode(false)
        history.push("/verification/" + verificationId)

    }

    const selectTool = () => {
        console.log("selectedTool:", toolId)
        if (toolId) {
            setToolInVerification(verification.tools.find(t => t.id == toolId))
            setReadMode(true)
        }
    }



    useEffect(selectTool, [toolId])

    return (
        <>
            {verification && verification.tools &&
                <Row className={"mt-2"}>
                    {!toolInVerification &&
                        <>
                            <Col>
                                <ToolSelectionSection
                                    verification={verification}
                                    verificationId={verificationId}
                                    setLoader={setLoader}
                                    confirmed={confirmed}
                                    setConfirmed={setConfirmed}
                                    setToolInVerification={setToolInVerification}
                                    reload={reload}
                                />
                            </Col>
                            <Col>

                                <ToolsSection
                                    verification={verification}
                                    verificationId={verificationId}
                                    setLoader={setLoader}
                                    reload={reload}
                                />
                            </Col>
                        </>
                    }

                    {
                        toolInVerification &&
                        <ToolVerification
                            verification={verification}
                            verificationId={verificationId}
                            setLoader={setLoader}
                            setToolInVerification={setToolInVerification}
                            toolInVerification={toolInVerification}
                            toolDetails={toolInVerification}
                            readOnly={readMode}
                            reload={reload}
                            toolSavedExternal={toolSavedExternal}
                        />
                    }
                </Row>
            }
        </>
    );
};


export default MeasurementsStep;