import React, {forwardRef} from "react";
import {Accordion, Button, Card} from "react-bootstrap";

const AccordionItem = forwardRef(({title, eventKey, activeKey, enable, completed, children, onEntered}, ref) => {
    return (
        <div ref={ref}>
            <Card className={"shadow "}>
                <Card.Header>
                    <Accordion.Toggle as={Button}
                                      className={"accordion-button"}
                                      variant="outline-primary"
                                      disabled={!enable}
                                      eventKey={eventKey}>
                        <span className={"h4 mr-3"}>
                            {completed ?
                                <i className="far fa-check-circle text-primary"></i> :
                                <i className="far fa-times-circle text-secondary"></i>
                            }
                        </span>
                        <span className={"h6 ali"}>{title}</span>
                    </Accordion.Toggle>

                    <div className={"float-right"}>
                        <Accordion.Toggle as={Button}
                                          className={"accordion-button"}
                                          variant="outline-primary"
                                          disabled={!enable}
                                          eventKey={eventKey}>
                            {eventKey === activeKey ?
                                <i className="fas fa-chevron-circle-up text-primary h5"></i> :
                                <i className="fas fa-chevron-circle-down text-primary h5"></i>
                            }
                        </Accordion.Toggle>
                    </div>
                </Card.Header>
            </Card>
            <Accordion.Collapse eventKey={eventKey} onEntered={onEntered}>
                <Card.Body>
                    {children}
                </Card.Body>
            </Accordion.Collapse>
        </div>
    );
});

export default AccordionItem;
