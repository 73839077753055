import * as React from "react";
import IconButton from "../../../components/button/IconButton";
import ConfirmButton from "../../../components/button/ConfirmButton";
import {Actions} from "../../../../client/Actions";
import ConfirmIconButton from "../../../components/button/ConfirmIconButton";


const columns = [

    {
        label: "ID",
        field: "id",
        sort: "desc",
        width: 53,
    },
    {
        label: "Nazwa szablonu",
        field: "templateName",
        sort: "desc",
        width: 53,
    },
    {
        label: "Numer wewnętrzny",
        field: "internalNumber",
        sort: "desc",
        width: 53,
    },
    {
        field: "toolLabelling",
        label: "Znakowanie narzędzi",
        sort: "desc",
        width: 53,

    },
    {
        field: "productName",
        label: "Nazwa produktu",
        sort: "desc",
        width: 53,
        type: "dictionary",
    },
    {
        field: "multipleTool",
        label: "Narzędzia wielokrotne",
        sort: "desc",
        width: 53,
        type: "boolean",
    },
    {
        field: "actions",
        label: "Akcje",
        sort: "desc",
        width: 53,
        type: "custom",
        custom: function (column, row, history, reload, setReload, setLoader) {
            return <ConfirmIconButton

                className={"btn-circle"}
                variant="outline-danger"
                icon="fa-trash"
                size={"sm"}
                tooltip={"Usuń"}
                confirmTitle={"Usunięcie szablonu"}
                confirmDescription={"Czy napewno chcesz usunąć szablon?"}
                onConfirm={() => {
                    setLoader(true)
                    Actions.delete("toolSetTemplate/" + row.id, {}, () => {
                        setReload(!reload)
                    })
                }}

                modalSize={'md'}
            />
            // <IconButton
            //     size={"sm"}
            //     onClick={() => {
            //         Actions.
            //         history.push("/verification/" + row.id);
            //     }}
            //     variant="outline-danger"
            //     icon="fa-arrow-right"
            //     tooltip={"Usuń"}
            // />
            // <Button as="a" size={"sm"} variant="outline-danger" className="btn-circle ml-1 tableInfoBtn"
            //                role="button"
            //                onClick={() => {
            //                    history.push("/verification/" + row.id);
            //                }}>
            // </Button>;
        }

    }


];

export default columns;