import * as React from "react";

const fields = {
    config: {columns: 1},
    fields: [
        {
            name: "headOk",
            label: "Ocena",
            column: 1,
            defaultValue: "true",
            type: "radio",
        }

    ]


};

export default fields;