import React, {useEffect, useState} from "react";

import "./NavigationBar.css";

import {Button, Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link, useHistory, withRouter} from "react-router-dom";
import Loader from "../../components/Loader";
import {
    getUser,
    hasFiltersAdd,
    hasGoToProdRead,
    hasManagementRead, hasPermission,
    hasTSAddition,
    hasWashingMachineRead
} from "../../helpers/jwt";
import config from "../menu/MenuConfig";
import IconButton from "../../components/button/IconButton";


const logoImage = "/assets/img/mpoint_logo.png";
const navigationBarTitleValue = <> <span className="d-none d-lg-inline mr-2 text-gray-600 small">{getUser()}</span><i
    className="far fa-user-circle" style={{fontSize: "22px"}}/> </>;


const NavigationBar = (props) => {

    const history = useHistory();

    function handleLogout() {
        setLoader(true);
        localStorage.removeItem("user_jwt");
        props.history.push("/login");
    }

    function handleMainPage() {
        props.history.push("/");
    }

    function handleToolSetPage() {
        props.history.push("/toolset");
    }

    function handleToolManagementPage() {
        props.history.push("/tool/management");
    }

    function handleProductionPage() {
        props.history.push("/filterSet/production");
    }

    const toggleJsUrl = "/assets/js/toggle.js";




    const initToggleScript = () => {
        const script = document.createElement("script");

        script.src = toggleJsUrl;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    };


    // loader
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        initToggleScript();
    }, []);

    return (
        <>
            <Loader loader={loader} setLoader={setLoader}/>
            <Navbar variant="light" fixed="top" bg="light" expand className="Navigation__wrapper  mb-4 topbar">
                <Container fluid>

                    <Navbar as="ul" className="navbar-nav center-nav">

                        <Nav>
                            <Nav.Item>
                                <Nav.Link active onClick={() => history.push("/verification")}>  <i className="fas fa-bars"></i>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link active onClick={() => history.push("/verification")}>Dodaj weryfikację</Nav.Link>
                            </Nav.Item>

                        </Nav>
                    </Navbar>

                    <Navbar as="ul" className="nav navbar-nav flex-nowrap ml-auto">
                        <div className="d-sm-block topbar-divider"></div>
                        <Nav as="li" className="nav-item dropdown no-arrow" role="presentation">
                            <NavDropdown className="no-arrow" title={navigationBarTitleValue}>

                                <NavDropdown.Item role="presentation" onClick={handleLogout}>
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"/>&nbsp;Wyloguj
                                </NavDropdown.Item>

                            </NavDropdown>
                        </Nav>
                    </Navbar>
                </Container>
            </Navbar>
        </>
    );
};

export default withRouter(NavigationBar);