import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import FormInputFile from "./file/FormInputFile";


const FileModal = ({ title, show, setShow, files, setFiles }) => {


  const handleCloseImagesModal = () => {
    setShow(false);
    setFiles([]);
  };

  return (
    <Modal show={show} onHide={handleCloseImagesModal}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate>

          <FormInputFile
            titleText={""}
            labelText={"Wybierz plik"}
            idName={"title"}
            path={"file"}
            inputSize={9}
            btnSize={3}
            requestParameterName={"file"}
            setImagesParentStore={setFiles}
            imagesParentStore={files} disabled={true
          }
          />

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseImagesModal}>Kontynuuj</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileModal;