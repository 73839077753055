import React from "react";
import {Container, Row, Tab, Tabs} from "react-bootstrap";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "./AuditComponent.css";
import auditConfig from "./auditConfig";
import AuditTab from "./AuditTab";
import {getUser} from "../../helpers/jwt";

const AuditComponent = ({ setLoader }) => {


  function createTab(tab) {
    return <Tab eventKey={tab.type} title={tab.title}>
      <AuditTab tab={tab} setLoader={setLoader}/>
    </Tab>;
  }

  return (

    <>


      <Container fluid className="mt-3 auditContainer">
        <Tabs defaultActiveKey={auditConfig.defaultTab} transition={false} id="noanim-tab-example-2">
          {
            auditConfig.tabs.map(tab => createTab(tab))
          }
        </Tabs>
      </Container>
    </>
  );
};

export default AuditComponent;
