const config = [
  {

    tittle: "Paneł głowny",
    navigate: "/",
    icon: "fa-home",
  },
  {

    tittle: "Weryfikacje",
    navigate: "/verifications",
    icon: "fa-tasks",
  },
  {

    tittle: "Szablony weryfikacji",
    navigate: "/verificationTemplates",
    icon: "fa-sticky-note",
  },

  // {
  //
  //   tittle: "Stany magazynowe filtrów gotowe do wydania na produkcję",
  //   navigate: "/filter/storage",
  //   icon: "fa-filter",
  // },
  // {
  //
  //   tittle: "Baza filtrów dedykowanych do produktów",
  //   navigate: "/filterSet",
  //   icon: "fa-layer-group",
  // },
  // {
  //   tittle: "Baza filtrów zutylizowanych",
  //   navigate: "/withdrawn",
  //   icon: "fa-window-close",
  // },
  // {
  //
  //   tittle: "Wydanie na produkcję",
  //   navigate: "/filterSet/production",
  //   icon: "fa-play",
  // },
  // {
  //
  //   tittle: "Produkcja",
  //   navigate: "/production",
  //   icon: "fa-project-diagram",
  // },
  // {
  //
  //   tittle: "Zarządzanie",
  //   navigate: "/management",
  //   icon: "fa-vials",
  //
  // },
  // {
  //   tittle: "Pralka i suszarka do filtrów",
  //   navigate: "/washingMachine",
  //   icon: "fa-dumpster",
  //
  // },
  // {
  //   tittle: "Raporty",
  //   navigate: "/reports",
  //   icon: "fa-chart-bar"
  // },
  {

    tittle: "Panel Administracyjny",
    navigate: "/admin",
    icon: "fa-users-cog",
  }
];

export default config;