import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Row} from "react-bootstrap";
import CustomCard from "../../components/CustomCard";
import CardFormDetails from "../../components/form/CardFormDetails";
import fields from "./config/ToolInfoFormConfig";
import CardForm from "../../components/form/CardForm";
import VisualVerificationFormConfig from "./config/VisualVerificationFormConfig";
import BtnSliderRoundedComponent from "../../components/switch/BtnSliderRoundedComponent";
import WebCam from "./WebCam";
import CardTable from "../../components/table/CardTable";
import FileTableConfig from "./config/FileTableConfig";
import FormInputFile from "../../components/file/FormInputFile";
import utils from "../../helpers/utils";
import {Actions} from "../../../client/Actions";
import VisualVerificationMatrixSegmentFormConfig from "./config/VisualVerificationMatrixSegmentFormConfig";

function VisualLegend() {
    return <>
        <Row className="text-secondary font-weight-normal visual-legend">
            <Col className={"float-right d-flex"}>
                <BtnSliderRoundedComponent checked={false} disabled={true} red={true}/> <p
                className="ml-2 mr-2"> - uszkodzony</p>
            </Col>
            <Col className={"float-right d-flex"}>
                <BtnSliderRoundedComponent checked={true} disabled={true} red={false}/> <span
                className="ml-2 mr-2"> - bez uszkodzeń</span>
            </Col>
        </Row>
    </>;
}

const ToolVerification = ({
                              toolInVerification,
                              toolSavedExternal,
                              verificationId,
                              readOnly,
                              verification,
                              setLoader,
                              reload
                          }) => {

    const [extended, setExtended] = useState(false);
    const [files, setFiles] = useState([]);
    const [measurements, setMeasurements] = useState();
    const [visual, setVisual] = useState();

    const getTipsMeasurementsFormConfig = () => {
        return {
            config: {columns: 1},
            fields: getTipsConfig()
        }
    }

    const getTipsConfig = () => {
        if (toolInVerification.tips.length > 1) {
            return toolInVerification.tips.map(t => {
                return configForTip(t)
            })
        } else {
            return toolInVerification.tips.map(t => {
                return singleTip(t)
            })
        }
    }

    const configForTip = (tip,) => {
        return {
            name: tip.tipNumber,
            label: "Tip " + tip.tipNumber + " - Długość (mm)",
            column: 1,
            type: "measurement",
            defaultValue: tip.length ? tip.length : undefined,
            filterTargetValue: function (val) {
                if (verification && verification.configuration) {
                    if (verification.configuration.measurementType === "WORKING_LENGTH") {
                        return val;
                    }
                    if (verification.configuration.measurementType === "TOTAL_LENGTH") {
                        const result = 133.60 - parseFloat(val);
                        return result.toFixed(2);
                    }
                }

                return parseInt(val);
            }
        }
    }

    const singleTip = (tip,) => {
        return {
            name: tip.tipNumber,
            label: "Długość (mm)",
            column: 1,
            type: "measurement",
            defaultValue: tip.length ? tip.length : undefined,
        }
    }

    const saveTool = () => {
        const toSave = {
            headOk: visual ? visual.headOk : true,
            stemOk: visual ? visual.stemOk : true,
            tipOk: visual ? visual.tipOk : true,
            tipsMeasurements: measurements,
            files: files,
            verificationId: verificationId
        }

        setLoader(true)
        Actions.post("tool/" + toolInVerification.id, toSave, toolSaved)
    }

    const cancel = () => {
        if (toolSavedExternal) {
            toolSavedExternal();
        }
    }

    const toolSaved = () => {
        if (toolSavedExternal) {
            toolSavedExternal();
        }
    }

    function updateFiles() {
        if (readOnly && toolInVerification) {
            setFiles(toolInVerification.files)
        }

    }

    useEffect(updateFiles, [toolInVerification])

    return (
        <>
            {toolInVerification && verification &&
                <Col>
                    <Row>
                        <Col>
                            <Alert className={"pb-4"} variant="primary"><i className="fas fa-info-circle"></i><strong>
                                {readOnly ? " Szczegóły weryfikacji narzędzia" : " W trakcie weryfikacji narzędzia"} </strong>


                                {!readOnly &&
                                    <Button type="submit" className={"float-right"} onClick={saveTool}
                                            variant="primary">{"Zapisz"}</Button>
                                }
                                <Button type="submit" className={"float-right mr-2"} onClick={cancel}
                                        variant="secondary">{readOnly ? "Wróć" : "Anuluj"}</Button>

                            </Alert>
                        </Col>

                    </Row>
                    <Row>
                        <CardFormDetails
                            setLoader={setLoader}
                            fields={fields}
                            width={4}
                            withoutHeader={true}
                            // className={"mt-2 mx-0 px-0"}
                            initialData={toolInVerification}
                            readonly={true}
                        />
                        {toolInVerification.toolType !== "MATRIX" && toolInVerification.toolType !== "SEGMENT" && verification.configuration.withMeasurements !== false &&
                            <CardForm
                                setLoader={setLoader}
                                fields={getTipsMeasurementsFormConfig()}
                                width={4}
                                customSave={() => {
                                }}
                                readOnly={readOnly}
                                withoutHeader={true}
                                noActions={true}
                                postSaved={() => {
                                }}
                                formDataChanged={setMeasurements}
                            />
                        }
                        {verification.configuration.withCamera !== false &&
                            <Col md={"4"} className={"mx-0 px-0"}>
                                <Row className={"mx-0 px-0"}>
                                    <CardForm
                                        className={"verification"}
                                        title={<VisualLegend/>}
                                        setLoader={setLoader}
                                        fields={toolInVerification.toolType === "MATRIX" || toolInVerification.toolType === "SEGMENT"
                                            ? VisualVerificationMatrixSegmentFormConfig
                                            : VisualVerificationFormConfig
                                        }
                                        width={12}
                                        readOnly={readOnly}
                                        customSave={() => {
                                        }}
                                        noActions={true}
                                        postSaved={() => {
                                        }}
                                        initialData={readOnly ? toolInVerification : undefined}
                                        formDataChanged={setVisual}

                                    />
                                </Row>
                            </Col>
                        }
                    </Row>
                    <Row>
                        {!readOnly && verification.configuration.withCamera !== false &&

                            <Col md={extended ? 12 : 8}>
                                <Row>
                                    <CustomCard
                                        withoutHeader={true}
                                        width={12}
                                    >

                                        <FormInputFile titleText={"Wgraj zdjęcie"}
                                                       labelText={"Wybierz plik"}
                                                       idName={"uploadFile"}
                                                       path={"file"}
                                                       withoutList={true}
                                                       requestParameterName={"file"}
                                                       setImagesParentStore={setFiles}
                                                       imagesParentStore={files}/>
                                    </CustomCard>

                                </Row>
                                <Row>
                                    <CustomCard
                                        withoutHeader={true}
                                        width={12}
                                    >
                                        <WebCam
                                            setLoader={setLoader}
                                            extended={extended}
                                            setExtended={setExtended}
                                            onFileUploadedExternal={(file) => {
                                                setFiles(old => [...old, file])
                                            }
                                            }
                                        />
                                    </CustomCard>
                                </Row>
                            </Col>
                        }

                        {!extended && verification.configuration.withCamera !== false &&
                            <Col md={extended ? 0 : (readOnly ? 8 : 4)}>
                                <Row className={"mx-0 px-0"}>
                                    <CardTable
                                        initData={files}
                                        columns={FileTableConfig}
                                        width={12}
                                        setLoader={setLoader}
                                        withoutHeader={true}
                                        reload={reload}
                                        striped={false}
                                        readOnly={readOnly}
                                        className={"mx-0 px-0"}
                                        shadow={true}
                                        actions={{
                                            remove: function (id) {
                                                setFiles(files.filter(f => f.id !== id))
                                            }
                                        }}
                                    />
                                </Row>
                            </Col>
                        }

                    </Row>
                </Col>
            }
        </>
    );
};


export default ToolVerification;