import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { getJwt } from '../helpers/jwt';

const AuthComponent = (props) => {


    useEffect(() => {
        const jwt = getJwt();
        if (!jwt) {
            props.history.push('/login');
        }

    });

    return props.children;
}

export default withRouter(AuthComponent);