import React, {useEffect, useState} from "react";
import {Button, Col, Form, Modal} from "react-bootstrap";


const ConfirmModal = ({
                          title,
                          ready,
                          description,
                          onConfirm,
                          show,
                          setShow,
                          size = "sm",
                          additionalFields,
                          alert,
                          children,
                          closable,
                          buttonsConf,
                          onCancel,
                          form
                      }) => {


    const [canConfirm, setCanConfirm] = useState(true);


    function cancel() {
        setShow(false);
        if (onCancel) {
            onCancel();
        }
    }

    function updateSetConfirm() {
        setCanConfirm(form ? ready : true);
    }


    useEffect(updateSetConfirm, [ready]);

    function confirm() {
        setShow(false);
        if (onConfirm) {
            onConfirm();
        }
    }


    function determineConfirmTittle() {
        if (buttonsConf) {
            return buttonsConf.save
        }
        if (alert) {
            return "Kontynuj";
        }

        if (form) {
            return "Zapisz";
        }

        return "Tak";
    }

    return (
        <Modal show={show} onHide={cancel} size={size}>
            <Modal.Header closeButton={closable !== false}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {additionalFields ?
                    <Form noValidate>
                        <Form.Row>
                            <Col>
                                <Form.Group controlId="model_modal_control">
                                    <Form.Label>{description}</Form.Label>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        {additionalFields}
                    </Form>
                    :
                    ""
                }

                {form ? form : ""}
                {children ? children : ""}
                {description && !additionalFields ? <>{description}</> : ""}
            </Modal.Body>
            <Modal.Footer>
                {!alert ?
                    <Button variant="light" onClick={cancel}>{buttonsConf ? buttonsConf.cancel : "Anuluj"}</Button>
                    : ""}
                <Button variant="primary" disabled={!canConfirm} className="Modal_btn_submit"
                        onClick={confirm}>{determineConfirmTittle()}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;