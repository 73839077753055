import * as React from "react";
import {Button} from "react-bootstrap";
import IconButton from "../../../components/button/IconButton";


const columns = [
    {
        field: "toolType",
        label: "Typ",
        sort: "desc",
        width: 53,
        type: "enum",
        enumType: "enums/toolType",
    },
    {
        label: "Numer",
        field: "toolNumber",
        sort: "desc",
        width: 53,
    },
    {
        field: "labelling",
        label: "Znakowanie",
        sort: "desc",
        width: 53
    },
    {
        field: "verified",
        label: "Zweryfikowano",
        sort: "desc",
        width: 53,
        type: "custom",
        custom: function (column, row, history) {
            return <>
                {
                    row.verified ?
                        <i className="far fa-check-circle text-primary"></i> :
                        <i className="far fa-times-circle text-secondary"></i>
                }
            </>
        }

    },
    {
        field: "actions",
        label: "Akcę",
        sort: "desc",
        width: 53,
        type: "custom",
        custom: function (column, row, history, additionalInformation) {
            return <>
                {row.verified ?
                    <IconButton variant="outline-primary"
                                size={"sm"}
                                className="btn-circle"
                                role="button"
                                icon={" fa-arrow-left "}
                                tooltip={"Szczegóły"}
                                onClick={() => {
                                    history.push("?tool=" + row.id);
                                }}
                    />
                    :""
                }
            </>;
        }

    }


];

export default columns;