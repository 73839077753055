import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';


const GoBackButton = ({ title, url }) => {
  return (
    <Row>
      <Col>
        <Link className="btn btn-outline-secondary mb-2" to={url}><i className="fas fa-arrow-left" />  {title}</Link>
      </Col>
    </Row>
  );
}

export default GoBackButton;