import React, {useState} from "react";
import ConfirmModal from "../modal/ConfirmModal";
import IconButton from "./IconButton";


const ConfirmIconButton = ({
                               confirmTitle,
                               confirmDescription,
                               onConfirm,
                               variant,
                               icon,
                               size,
                               tooltip,
                               children,
                               className,
                               disabled,
                               additionalFields,
                               modalSize = "sm"
                           }) => {

    const [show, setShow] = useState(false);

    const onClick = () => {
        setShow(true);
    };

    return (
        <>
            <IconButton variant={variant}
                        className={className}
                        disabled={disabled}
                        icon={icon}
                        size={size}
                        tooltip={tooltip}
                        onClick={onClick}>{children}</IconButton>
            <ConfirmModal
                title={confirmTitle}
                description={confirmDescription}
                show={show}
                setShow={setShow}
                onConfirm={onConfirm}
                additionalFields={additionalFields}
                size={modalSize}
            />
        </>
    );
};

export default ConfirmIconButton;