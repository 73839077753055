import React, {useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import AuditSearchForm from "./AuditSearchForm";
import CardTable from "../table/CardTable";
import {fetchData} from "../../../client/Actions";
import ModalForm from "../form/ModalForm";
import {getUser} from "../../helpers/jwt";
import CustomCard from "../CustomCard";
import dateUtils from "../../helpers/dateUtils";

const AuditTab = ({tab, setLoader}) => {


    const [tableData, setTableData] = useState();
    const [filterSetDetailsContext, setFilterSetDetailsContext] = useState();
    const [filterDetailsContext, setFilterDetailsContext] = useState();

    const infoAction = (column, row, history) => {
        if (tab.infoAction) {
            if (tab.infoAction.redirectTo) {
                var win = window.open(tab.infoAction.redirectTo(row), "_blank");
                win.focus();
            }
            if (tab.infoAction.showFilterSetDetails) {
                showFiltersSetDetails(row.businessId)
            }
            if (tab.infoAction.showFilterDetails) {
                showFiltersDetails(row.filterId)
            }
        }
    };

    function getColumns() {
        const basic = [
            {
                field: "date",
                label: "Data",
                sort: "desc",
                width: 53
            },
            {
                field: "username",
                label: "Użytkownik",
                sort: "desc",
                width: 53,
                parent: "user"

            },
            {
                field: "auditActionType",
                label: "Akcja",
                sort: "desc",
                width: 200,
                type: "enum",
                enumType: "auditActionType"
            }
        ];


        const info =
            !tab.infoAction ? [] :
                [
                    {
                        field: "actions",
                        label: "Akcje",
                        sort: "desc",
                        width: 53,
                        type: "custom",
                        custom: function (column, row, history) {
                            return <Button as="a" variant="primary" size="sm" className="btn-circle ml-1 tableInfoBtn"
                                           role="button"
                                           onClick={() => infoAction(column, row, history)}>
                                <i className="fas fa-info-circle text-white"/>
                            </Button>;
                        }

                    }
                ];

        return basic.concat(tab.additionalFields).concat(info);
    }


    async function showFiltersDetails(filterId) {
        let filterDetails = await fetchData("filters/" + filterId);
        let dryer = await fetchData("dryers/" + filterDetails.dryerId);
        filterDetails.dryer = dryer;


        setFilterDetailsContext({
                filter: filterDetails,
                show: true
            }
        );
    }

    async function showFiltersSetDetails(businessId) {
        let filterSetDetails = await fetchData("filter/set/business/" + businessId);

        if (filterSetDetails) {
            setFilterSetDetailsContext({
                    filterSet: filterSetDetails,
                    show: true
                }
            );
        }


    }


    return (
        <>



            <Row>
                <Col className="pl-0">
                    <AuditSearchForm apiType={tab.type} storeSetter={setTableData}/>
                </Col>
            </Row>

            <CustomCard
                className={"mx-0 px-0"}
                withoutHeader={true}
                shadow={false}>
                <span className="badge badge-primary mr-3">Raport śledzenia</span>
                <span className="text-secondary mr-1">Użytkownik</span>
                <span className="badge badge-primary mr-3">{getUser()}</span>
                <span className="text-secondary mr-1">Data</span>
                <span className="badge badge-primary mr-3">{dateUtils.getCurrentDateTime("-")}</span>
            </CustomCard>
            <Row>
                <Col className="pl-0">
                    <CardTable
                        withoutHeader={true}
                        className={"mt-2 p-0"}
                        columns={getColumns()}
                        initData={tableData}
                        setLoader={setLoader}
                        shadow={false}
                    />
                </Col>
            </Row>


        </>
    );
};

export default AuditTab;
