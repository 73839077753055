import React, { useState } from "react";
import pagesActions from "../pagesActions";
import Loader from "../../components/Loader";

import "./LoginPage.css";

const logoImage = '/assets/img/mpoint_logo.png';

const ErrorMessageBlock = (props) => <div className="alert alert-danger bounce animated" role="alert"><span><strong>{props.message}</strong></span></div>

const LoginPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState({
    isError: false,
    errorMsg: ""
  });

  // loader
  const [loader, setLoader] = useState(false);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setLoader(true);
    pagesActions.login(email, password, callbackProcess);
    pagesActions.validateUser(props);
  }

  const callbackProcess = (data) => {
    if (data.status === 'OK') {
      localStorage.setItem("user_jwt", data.msg.jwtToken);
      localStorage.setItem("user", data.msg.user);
      localStorage.setItem("perms", data.msg.perms);
      setError({
        isError: false,
        errorMsg: ""
      });

      window.location.replace('/');
    }
    if (data.status === 'ERROR') {
      setLoader(false);
      setError({
        isError: true,
        errorMsg: data.msg
      });
    }
  };

  return (
    <>
      <Loader loader={loader} setLoader={setLoader} />
      <div className="bg-gradient-primary">
        <div className="Login__form">
          <form onSubmit={handleSubmit}>
            <h2 className="sr-only">Login Form</h2>
            <div className="logo">
              <img src={logoImage} alt="Company logo" />
            </div>

            {error.isError ? ErrorMessageBlock(error.errorMsg) : null}

            <div className="form-group">
              <input className="form-control" name="username" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
            </div>
            <div className="form-group">
              <input className="form-control" type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Hasło" />
            </div>
            <div className="form-group">
              <button className="btn btn-primary btn-block" disabled={!validateForm()} type="submit">Log In</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};


export default LoginPage;