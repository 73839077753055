import React, {useEffect, useRef, useState} from 'react';
import {Container, Button, Form, Row, Col, Modal, ButtonGroup} from 'react-bootstrap';
import {CSSTransition} from 'react-transition-group';
import IconButton from "../../components/button/IconButton";
import ModalForm from "../../components/form/ModalForm";
import fields from "./config/FileUploadFormConfig";
import {Actions} from "../../../client/Actions";

const WebCam = ({
                    extended: initialExtended = false,
                    setExtended: setExternalExtended,
                    requestParameterName = 'file',
                    onFileUploadedExternal,
                    setLoader
                }) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [brightness, setBrightness] = useState(100);
    const [isNegative, setIsNegative] = useState(false);
    const [redFilter, setRedFilter] = useState(100);
    const [greenFilter, setGreenFilter] = useState(100);
    const [blueFilter, setBlueFilter] = useState(100);
    const [zoom, setZoom] = useState(1);
    const [extended, setExtendedState] = useState(initialExtended);
    const [showModal, setShowModal] = useState(false);
    const [store, setStore] = useState(null);

    const setExtended = (value) => {
        setExtendedState(value);
        if (setExternalExtended) setExternalExtended(value);
    };

    useEffect(() => {
        const getDinoCamera = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter((device) => device.kind === 'videoinput');
                const dinoCamera = videoDevices.find((device) => device.label.includes('Dino'));

                const constraints = {
                    video: dinoCamera ? {deviceId: {exact: dinoCamera.deviceId}} : true,
                };

                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (err) {
                console.error('Error accessing webcam:', err);
            }
        };

        getDinoCamera();
    }, []);

    const constructFilter = () => {
        const brightnessFilter = `brightness(${brightness}%)`;
        const negativeFilter = isNegative ? 'invert(100%)' : '';
        const red = `contrast(${redFilter}%)`;
        const green = `contrast(${greenFilter}%)`;
        const blue = `contrast(${blueFilter}%)`;
        return `${brightnessFilter} ${negativeFilter} ${red} ${green} ${blue}`;
    };

    const handleBrightnessChange = (e) => {
        setBrightness(e.target.value);
    };

    const handleColorChange = (e, color) => {
        if (color === 'red') setRedFilter(e.target.value);
        else if (color === 'green') setGreenFilter(e.target.value);
        else if (color === 'blue') setBlueFilter(e.target.value);
    };

    const toggleNegative = () => {
        setIsNegative(!isNegative);
    };

    const toggleZoom = () => {
        setZoom((prevZoom) => (prevZoom === 1 ? 2 : 1));
    };

    const restoreDefaults = () => {
        setBrightness(100);
        setIsNegative(false);
        setRedFilter(100);
        setGreenFilter(100);
        setBlueFilter(100);
        setZoom(1);
    };

    const capturePhoto = () => {
        const context = canvasRef.current.getContext('2d');
        context.filter = constructFilter();
        const width = canvasRef.current.width / zoom;
        const height = canvasRef.current.height / zoom;
        context.drawImage(videoRef.current, 0, 0, width, height, 0, 0, canvasRef.current.width, canvasRef.current.height);
        const imageDataUrl = canvasRef.current.toDataURL('image/png');

        const imageBlob = dataURItoBlob(imageDataUrl);
        setStore(imageBlob);
        setShowModal(true);
    };

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const buffer = new ArrayBuffer(byteString.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < byteString.length; i++) {
            view[i] = byteString.charCodeAt(i);
        }
        return new Blob([buffer], {type: mimeString});
    };

    const validateAndUpload = (data) => {


        const newformData = new FormData();
        newformData.append(requestParameterName, new File([store], `${data.fileName}.png`, {type: 'image/png'}));
        newformData.append('description', data.fileDescription || '');
        Actions.post('file', newformData, onFileSaved);
    };

    const onFileSaved = (data) => {
        if (onFileUploadedExternal) {
            onFileUploadedExternal({
                id: data.id,
                fileName: data.fileName,
                filePath: data.filePath,
                fileDescription: data.fileDescription
            });
        }
        // reset
        setStore(null);
        setLoader(false);
    };


    return (
        <Container fluid className="p-3">
            <Row>
                <Col sm={extended ? 8 : 11} className="d-flex align-items-center">
                    <div style={{width: '100%', aspectRatio: '16/9', overflow: 'hidden'}}>
                        <video
                            autoPlay
                            playsInline
                            muted
                            ref={videoRef}
                            style={{
                                filter: constructFilter(),
                                width: `${100 * zoom}%`,
                                height: 'auto',
                                transform: `translate(-${(zoom - 1) * 50}%, -${(zoom - 1) * 50}%)`,
                            }}
                        />
                    </div>
                </Col>
                <Col sm={1} className="transition-col">


                    <ButtonGroup vertical>
                        <IconButton size={"ms"} icon={"fa-camera"}
                                    onClick={capturePhoto}
                                    variant={"outline-primary"}/>


                        <Button onClick={() => setZoom(1)}>x1</Button>
                        <Button onClick={() => setZoom(2)}>x2</Button>

                        <IconButton size={"m"} icon={"fa-adjust"}
                                    onClick={toggleNegative}
                                    tooltip={isNegative ? 'Usuń negatyw' : 'Dodaj negatyw'}

                                    variant={"outline-secondary"}/>
                        <i className="fab fa-creative-commons-sa"></i>
                        <i className="fab fa-creative-commons-sa"></i>
                        <i className="fab fa-creative-commons-sa"></i>

                        <IconButton size={"ms"} icon={"fa-minus-circle"}
                                    onClick={restoreDefaults}
                                    tooltip={"Resetuj filtry"}
                                    variant={"danger"}/>

                        <i className="fab fa-creative-commons-sa"></i>
                        <i className="fab fa-creative-commons-sa"></i>
                        <i className="fab fa-creative-commons-sa"></i>

                        <IconButton className="mb-2 h5" size={"ms"}
                                    icon={extended ? "fa-chevron-circle-left" : "fa-chevron-circle-right"}
                                    onClick={() => setExtended(!extended)}
                                    tooltip={"Rozwiń filtry"}
                                    variant={"outline-primary"}/>

                    </ButtonGroup>


                </Col>
                <Col sm={extended ? 3 : 0} className="transition-col">
                    <CSSTransition
                        in={extended}
                        timeout={300}
                        classNames="expand"
                        unmountOnExit
                    >
                        <div>
                            <Form className="mt-3">
                                <Form.Group>
                                    <Form.Label><i className="fas fa-sun"></i> Jasność</Form.Label>
                                    <Form.Control
                                        type="range"
                                        min="0"
                                        max="200"
                                        value={brightness}
                                        onChange={handleBrightnessChange}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Czerwony</Form.Label>
                                    <Form.Control
                                        type="range"
                                        min="0"
                                        max="200"
                                        value={redFilter}
                                        onChange={(e) => handleColorChange(e, 'red')}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Zielony</Form.Label>
                                    <Form.Control
                                        type="range"
                                        min="0"
                                        max="200"
                                        value={greenFilter}
                                        onChange={(e) => handleColorChange(e, 'green')}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Niebieski</Form.Label>
                                    <Form.Control
                                        type="range"
                                        min="0"
                                        max="200"
                                        value={blueFilter}
                                        onChange={(e) => handleColorChange(e, 'blue')}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    </CSSTransition>
                </Col>
            </Row>
            <canvas ref={canvasRef} style={{display: 'none'}} width="720" height="405"></canvas>


            <ModalForm
                fields={fields}
                setLoader={setLoader}
                open={showModal}
                setOpen={() => setShowModal(false)}
                shadow={false}
                title={"Informację o pliku"}
                formTitle={"Nowy plik"}
                size={"md"}
                performReload={() => {
                }}
                onFinish={() => {
                }}
                customSave={validateAndUpload}
            />
        </Container>
    );
};

export default WebCam;
