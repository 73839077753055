import React from "react";
import {Button} from "react-bootstrap";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const IconButton = ({variant, icon, className, tooltip, size, onClick, disabled}) => {

    return (
        <>
            <Tippy content={tooltip} interactive={true} placement="bottom">

                <Button variant={variant}
                        size={size ? size : "md"}
                        disabled={disabled}
                        className={className + " icon-button"}
                        role="button"
                        onClick={onClick}>
                    <i className={"fas " + icon}/>
                </Button>
            </Tippy>
        </>
    );
};


export default IconButton;