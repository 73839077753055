import React from 'react';
import LoaderComponent from 'react-loader-spinner'

const Loader = ({ loader, setLoader }) => {
  return (
    <>
      <LoaderComponent className="loading"
        type="Oval"
        color="#ea9751"
        visible={loader}
        height={100}
        width={100}
      />
    </>
  );
}

export default Loader;