import React, {useEffect, useState, useRef} from "react";
import {Button} from "react-bootstrap";

const MeasurementOverlay = ({show, setShow, setValue}) => {
    const [isListening, setIsListening] = useState(false);
    const [localValue, setLocalValue] = useState("");
    const overlayMessageRef = useRef(null);

    const handleShowChanged = () => {
        if (show) {
            setLocalValue("");
            setIsListening(true);
            setTimeout(() => {
                overlayMessageRef.current && overlayMessageRef.current.focus();
            }, 0); // Focus on the message text
        } else {
            setIsListening(false);
        }
    };

    const handleCancelClick = () => {
        setShow(false);
        setIsListening(false);
        setLocalValue("");
    };

    const handleKeyDown = (event) => {
        if (!isListening) return;

        if (event.key === "Enter") {
            console.log("Input value:", localValue);
            setValue(localValue);
            setShow(false);
            setIsListening(false);
        } else {
            setLocalValue((prev) => prev + event.key);
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [localValue, isListening]);

    useEffect(handleShowChanged, [show]);

    return (
        <div className="m-app">
            {show && (
                <div className="m-overlay">
                    <div
                        className="m-overlay-message"
                        tabIndex="0"
                        ref={overlayMessageRef}
                    >
                        <img
                            src="/assets/img/mpoint_logo.png"
                            alt="Overlay Image"
                            className="m-overlay-image"
                        />
                        <h2>Wykonaj pomiar na urządzeniu</h2>
                        <p>Wartość: {localValue}</p>
                        <p>System w trakcie oczekiwania pomiaru...</p>
                        <Button
                            variant="outline-secondary"
                            className="mt-3"
                            onClick={handleCancelClick}
                        >
                            Anuluj
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MeasurementOverlay;
