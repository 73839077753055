export function isValid(field, formData) {
    const value = formData[field.name];

    if (field.validate) {

        if (field.validate.compareWith) {
            const compareWithValue = formData[field.validate.compareWith];

            return field.validate.compare(value, compareWithValue);
        }

        return field.validate(value);
    } else if (isMandatory(field, formData)) {
        return value !== undefined && value !== "" && value !== null;
    }

    return true;
}

function isMandatory(field, formData) {
    if (field.conditionalOn) {
        return field.mandatory && conditionalSuccess(field, formData);
    } else {
        return field.mandatory;
    }
}


export function conditionalSuccess(field, formData) {
    let conditionalOnValue = formData[field.conditionalOn];

    if (field.conditionalOnMatchValue) {
        return conditionalOnValue === field.conditionalOnMatchValue;
    }

    if (field.conditionalOnNotNull) {
        return !!conditionalOnValue;
    }

    return conditionalOnValue === "true" || conditionalOnValue === true;
}


export function determineDefault(field, initialData, dictionaries) {
    const {type, dictionary, name, defaultValue} = field;

    console.log("determine " + name);
    if (initialData && Object.hasOwn(initialData, name)) {
        return initialData[name];
    } else if (defaultValue) {
        return defaultValue;
    } else if (!type || type === "enum" || type === "number" || type === "number") {
        return undefined;
    } else if (type === "radio") {
        return false;
    } else if (type === "boolean") {
        return "";
    } else if (type === "file") {
        return [];
    } else if (type === "checkGroup") {
        return [];
    } else if (type === "dictionary") {
        const values = dictionaries[dictionary];
        console.log(dictionaries);

        if (values) {
            console.log(values);
            let dictionaryDefaultValue;
            // const dictionaryDefaultValue = values.find(v => v.defaultValue === "true" || v.defaultValue === true);
            if (Array.isArray(values)) {
                dictionaryDefaultValue = values.find(v => v.id === 1 || v.id === "1");
            }

            if (dictionaryDefaultValue) {
                // return dictionaryDefaultValue;
            }
        }

        return undefined;
    } else {
        return undefined;
    }
}